import React from 'react'

import usePrivateApi from '../../../../../hooks/usePrivateApi'
import Button from '../../../../../components/Button'
import Dialog from '../../../../../components/Dialog'

const UsersResendInviteModal = (props) => {
  const { dealerId, isOpen, setIsOpen, membership } = props

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/dealers/${dealerId}/users/invite/${membership.id}`,
      method: 'post',
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not resend invite. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="User invite sent"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          We have sent an email invite to{' '}
          <strong>
            {membership.user.firstName} {membership.user.lastName}'s
          </strong>{' '}
          email address ({membership.user.email}).
        </p>
        <p className="mt-2 text-gray-600 text-sm">
          Please ask <strong>{membership.user.firstName}</strong> to check their
          email inbox, click the link we sent them and set a password for their
          DK account. This invitation will expire in 24 hours, in which case you
          will need to resend the invitation.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Resend invite"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Sending...' : 'Send invite',
        color: 'green',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        Are you sure you wish to send{' '}
        <strong>
          {membership.user.firstName} {membership.user.lastName}
        </strong>{' '}
        a new invite?
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        The old invitation email will no longer work, so make sure{' '}
        <strong>{membership.user.firstName}</strong> uses the new email we send
        them.
      </p>
    </Dialog>
  )
}

export default UsersResendInviteModal
