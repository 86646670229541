import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faCalendarAlt,
  faAngleRight,
} from '@fortawesome/pro-light-svg-icons'

import Label from '../../../components/Label'
import { getStatus, getStatusLabelColor } from '../../../utils/dealers'

const DealerListItem = ({ membership, page, pathname, filters }) => {
  return (
    <Link
      className="border-b border-gray-100 relative flex flex-1 space-x-3 py-4 pr-3 pl-7 hover:bg-gray-50"
      to={{
        pathname: `/dealers/view/${membership.dealer.id}`,
        state: {
          dealer: membership.dealer,
          listState: {
            page,
            pathname,
            filters,
          },
        },
      }}
    >
      <div className="min-w-[260px] flex-1">
        <p className="text-black text-sm mb-1 font-medium">
          {membership.dealer.name}
        </p>
        <p className="text-gray-600 text-xs">
          <FontAwesomeIcon
            className="text-gray-500 mr-2"
            icon={faEnvelope}
            size="lg"
          />
          {membership.user.email}
        </p>
      </div>
      <div className="min-w-[320px] flex-1 flex-shrink-0">
        <p className="text-gray-900 mb-1.5 text-sm">
          DK number {membership.dealer.dkNumber}
          <Label
            color={getStatusLabelColor(membership.dealer.status)}
            className="ml-2"
          >
            {getStatus(membership.dealer.status)}
          </Label>
        </p>
        <p className="text-gray-600 text-xs mr-4">
          <FontAwesomeIcon
            className="text-gray-500 mr-2"
            icon={faCalendarAlt}
            size="lg"
          />
          Last logged in{' '}
          {format(
            new Date(membership.meta.lastLoggedIn),
            "dd MMMM yyy 'at' hh:mma"
          )}
        </p>
      </div>
      <div>
        <FontAwesomeIcon
          className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-400"
          icon={faAngleRight}
          size="lg"
        />
      </div>
    </Link>
  )
}

export default DealerListItem
