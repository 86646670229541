import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import history from '../../../history'
import useObjState from '../../../hooks/useObjState'
import usePrivateSocket from '../../../hooks/usePrivateSocket'
import {
  FETCH_TICKET,
  TICKET_OPENED,
  TICKET_CLOSED,
  NEW_FILES,
  NEW_MESSAGE,
} from '../../../utils/sockets'
import { OPEN, CLOSED } from '../../../utils/support'
import ViewTicketData from './Ticket'
import ViewTicketError from './Error'

const ViewTicket = ({ location, match, isAdmin }) => {
  const [connectionErr, setConnectionErr] = useState(null)
  const [hasFetchedTicket, setHasFetchedTicket] = useState(false)
  const [isFetchingTicket, setIsFetchingTicket] = useState(true)

  const [ticket, setTicket] = useObjState({
    id: match.params.ticketId,
    messages: _.get(location, 'state.ticket.messages', []),
    subject: _.get(location, 'state.ticket.subject', ''),
    category: _.get(location, 'state.ticket.category', null),
    files: _.get(location, 'state.ticket.files', []),
    status: _.get(location, 'state.ticket.status', null),
    createdBy: _.get(location, 'state.ticket.createdBy', null),
    ticketNumber: _.get(location, 'state.ticket.ticketNumber', ''),
  })

  const [{ inRoom, err }, { socket, resetErr }] = usePrivateSocket(
    '/support',
    match.params.ticketId
  )

  useEffect(() => {
    // match.params.uploadId has changed
    if (!inRoom && hasFetchedTicket) {
      setHasFetchedTicket(false)
      setIsFetchingTicket(true)
    }
  }, [inRoom, hasFetchedTicket])

  useEffect(() => {
    if (inRoom && !hasFetchedTicket) {
      setHasFetchedTicket(true)

      socket.emit(FETCH_TICKET, (err, _ticket) => {
        if (_ticket) {
          setTicket({ ...ticket, ..._ticket })
          setIsFetchingTicket(false)
        } else {
          // We had a problem finding the ticket
          setConnectionErr(true)
        }
      })
    }
  }, [inRoom, socket, ticket, setTicket, hasFetchedTicket])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_FILES, (files) => {
        setTicket({ files })
      })

      return () => socket.off(NEW_FILES)
    }
  }, [socket, ticket, setTicket])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_MESSAGE, (message) => {
        setTicket({ messages: [...ticket.messages, message] })
      })

      return () => socket.off(NEW_MESSAGE)
    }
  }, [socket, ticket, setTicket])

  useEffect(() => {
    if (socket) {
      socket.on(TICKET_OPENED, () => {
        setTicket({ status: OPEN })
      })

      return () => socket.off(TICKET_OPENED)
    }
  }, [socket, setTicket])

  useEffect(() => {
    if (socket) {
      socket.on(TICKET_CLOSED, () => {
        setTicket({ status: CLOSED })
      })

      return () => socket.off(TICKET_CLOSED)
    }
  }, [socket, setTicket])

  useEffect(() => {
    if (err) {
      // We couldn't connect to server
      setConnectionErr(true)
      resetErr()
    }
  }, [err, resetErr])

  useEffect(() => {
    const interceptBackButton = () => {
      if (document.location.pathname.includes('/support/list')) {
        if (location?.state?.listState) {
          const { page, pathname, filters } = location.state.listState
          history.push(pathname, { page, filters })
        }
      }
      window.removeEventListener('popstate', interceptBackButton)
    }

    window.addEventListener('popstate', interceptBackButton)
  }, [location])

  if (connectionErr) {
    return <ViewTicketError ticket={ticket} />
  }

  return (
    <ViewTicketData
      isAdmin={isAdmin}
      ticket={ticket}
      setTicket={setTicket}
      hasFetchedTicket={hasFetchedTicket}
      isLoading={isFetchingTicket}
      match={match}
      location={location}
      socket={socket}
      setConnectionErr={setConnectionErr}
    />
  )
}

export default ViewTicket
