import React, { useCallback } from 'react'
import axios from 'axios'
import { format } from 'date-fns'

import useLocalStorage from '../../../hooks/useLocalStorage'
import RadioGroup from '../../RadioGroup'
import InputGroup from '../../InputGroup'
import { toCapitalCase } from '../../../utils/strings'

const API_URL = import.meta.env.VITE_API_URL

const UploadOption = ({ innerProps, data }) => {
  return (
    <div {...innerProps} className="react-select__option">
      <span className="react-select__option-title">{data.label}</span>
      <span className="react-select__option-subtitle">
        {data.vrm || 'No VRM'} &middot; {data.reference || 'No Reference'}
      </span>
      <span className="react-select__option-meta">
        Uploaded {format(new Date(data.createdAt), "dd MMM yyyy 'at' HH:mm")}
      </span>
    </div>
  )
}

const NewUploadTypeForm = (props) => {
  const {
    isLoading,
    uploadType,
    setUploadType,
    setVehicle,
    originalUpload,
    setOriginalUpload,
    vehicleType,
    setVehicleType,
  } = props

  const [getAuthToken] = useLocalStorage('authToken')
  const authToken = getAuthToken()

  const fetchFullUploads = useCallback(
    async (search) => {
      try {
        const res = await axios({
          url: '/uploads/full',
          baseURL: API_URL,
          headers: { Authorization: `Bearer ${authToken}` },
          params: {
            search: search || undefined,
          },
        })

        if (res.data.uploads) {
          return res.data.uploads.map((upload) => {
            let label = `#${upload.uploadNumber} - ${toCapitalCase(
              upload.vehicleType
            )} - `

            if (upload.vehicleType === 'CAR') {
              label = `${label}${upload.vehicle.make} ${upload.vehicle.model}`
            } else {
              label = `${label}${upload.manualVehicle.make} ${upload.manualVehicle.model}`
            }

            return {
              ...upload,
              label,
              value: upload._id,
            }
          })
        }
      } catch (err) {
        throw Error(err)
      }
    },
    [authToken]
  )

  const handleTypeChangeToFull = useCallback(() => {
    setVehicle(
      // prettier-ignore
      vehicleType === 'CAR'
        ? null
        : {
          make: '',
          model: '',
          engineSize: '',
          hpModel: '',
          year: '',
        }
    )

    setOriginalUpload(null)
    setUploadType('FULL')
  }, [setOriginalUpload, setUploadType, setVehicle, vehicleType])

  const handleOnUploadChange = useCallback(
    (originalUpload) => {
      if (originalUpload) {
        originalUpload.id = originalUpload._id

        if (originalUpload.vehicleType === 'CAR') {
          originalUpload.vehicle.id = originalUpload.vehicle._id
          setVehicle(originalUpload.vehicle)
          setVehicleType('CAR')
          return setOriginalUpload(originalUpload)
        }

        setVehicle(originalUpload.manualVehicle)
        setVehicleType(originalUpload.vehicleType)
        return setOriginalUpload(originalUpload)
      }

      setVehicle(
        // prettier-ignore
        vehicleType === 'CAR'
          ? null
          : {
            make: '',
            model: '',
            engineSize: '',
            hpModel: '',
            year: '',
          }
      )
      setOriginalUpload(null)
    },
    [setOriginalUpload, setVehicle, vehicleType, setVehicleType]
  )

  const OptionContainer = useCallback(
    ({ children }) => <div className="space-y-3">{children}</div>,
    []
  )

  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 pb-8 mt-8">
      <div className="flex-[2]">
        <h3 className="text-xl font-semibold tracking-tight">Upload type</h3>
        <div className="space-y-2 mt-1 text-gray-700 text-sm">
          <p>
            By selecting "Full remap" we will automatically deduct from your
            account:
          </p>
          <ul>
            <li>Cars, motorbikes and boats: 1 credit</li>
            <li>Trucks and tractors: 3 credits</li>
          </ul>
          <p className="pt-2">
            By selecting "Adjustment remap" we will automatically deduct from
            your account:
          </p>
          <ul>
            <li>Cars, motorbikes and boats: 0.5 credit</li>
            <li>Trucks and tractors: 1.5 credits</li>
          </ul>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-4">
        <RadioGroup
          selected={uploadType}
          setSelected={(item) => {
            if (item === 'FULL') {
              return handleTypeChangeToFull()
            }

            return setUploadType('ADJUST')
          }}
          Container={OptionContainer}
          items={[
            {
              label: 'Full remap',
              value: 'FULL',
              disabled: isLoading,
              description:
                'This is the first time you are remapping this vehicle.',
            },
            {
              label: 'Adjustment remap',
              value: 'ADJUST',
              description: 'You need an adjustment to an existing remap.',
              renderContent: ({ checked }) =>
                checked ? (
                  <InputGroup
                    required
                    className="mt-2"
                    type="async-select"
                    label="Original upload"
                    id="original-upload"
                    disabled={isLoading}
                    onChange={handleOnUploadChange}
                    value={originalUpload}
                    isClearable
                    placeholder="Select an upload"
                    loadOptions={fetchFullUploads}
                    components={{ Option: UploadOption }}
                  />
                ) : null,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default NewUploadTypeForm
