import React from 'react'

import DataItem from './DataItem'
import { getWarranty } from '../../../utils/uploads'
import { getCountry } from '../../../utils/address'

const toYN = (value) => (value ? 'Yes' : 'No')

const Addons = ({ upload }) => {
  const { warranty, filters } = upload.addons.reduce(
    (acc, addon) => {
      if (addon.type === 'WARRANTY_12' || addon.type === 'WARRANTY_24') {
        acc.warranty.available = true

        if (addon.purchased) {
          acc.warranty = { ...acc.warranty, ...addon }
        }
      }

      if (addon.type === 'FILTERS_1' || addon.type === 'FILTERS_2') {
        acc.filters.available = true

        if (addon.purchased) {
          acc.filters = { ...acc.filters, ...addon }
        }
      }

      return acc
    },
    {
      warranty: {
        purchased: false,
        selected: false,
        available: false,
        type: 'WARRANTY_12',
      },
      filters: {
        purchase: false,
        selected: false,
        available: false,
        type: 'FILTERS_1',
      },
    }
  )

  return (
    <div className="divide-y divide-gray-200">
      {warranty.available && (
        <div className="pt-6 pb-4 pl-6">
          <h4 className="font-semibold tracking-tight text-lg mb-0.5">
            Mechanical warranty
          </h4>
          <p className="text-gray-600 text-sm">
            12 or 24 month mechanical warranty for your customer's vehicle.
          </p>
          <div className="divide-y divide-gray-200 mt-2">
            <DataItem label="Purchased" value={toYN(warranty.purchased)} />
            {warranty.purchased && (
              <>
                <DataItem label="Term" value={getWarranty(warranty.type)} />
                <DataItem
                  label="Customer name"
                  value={warranty.data.customerName}
                />
                <DataItem
                  label="Customer email"
                  value={warranty.data.customerEmail}
                />
              </>
            )}
          </div>
        </div>
      )}
      {filters.available && (
        <div className="pt-6 pb-4 pl-6">
          <h4 className="font-semibold tracking-tight text-lg mb-0.5">
            High-flow TGT filter
          </h4>
          <p className="text-gray-600 text-sm">
            Topgear Tuning designed performance air filter.
          </p>
          <div className="divide-y divide-gray-200 mt-2">
            <DataItem label="Purchased" value={toYN(filters.purchased)} />
            {filters.purchased && (
              <>
                <DataItem
                  label="Number of filters"
                  value={filters.type === 'FILTERS_1' ? 1 : 2}
                />
                <DataItem
                  label="Customer name"
                  value={filters.data.customerName}
                />
                <DataItem
                  label="Customer email"
                  value={filters.data.customerEmail}
                />
                <DataItem label="Shipping address" />
                <DataItem
                  label="Address line 1"
                  value={filters.data.shippingAddress.line1}
                />
                <DataItem
                  label="Address line 2"
                  value={filters.data.shippingAddress.line2}
                />
                <DataItem
                  label="City"
                  value={filters.data.shippingAddress.city}
                />
                <DataItem
                  label="Region"
                  value={filters.data.shippingAddress.region}
                />
                <DataItem
                  label="Postcode"
                  value={filters.data.shippingAddress.postcode}
                />
                <DataItem
                  label="Country"
                  value={getCountry(filters.data.shippingAddress.country)}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Addons
