export const AUTH = 'AUTH'
export const JOIN_ROOM = 'JOIN_ROOM'
export const LEAVE_ROOM = 'LEAVE_ROOM'

export const NEW_MESSAGE = 'NEW_MESSAGE'
export const SEND_MESSAGE = 'SEND_MESSAGE'

export const NEW_FILES = 'NEW_FILES'
export const UPLOAD_FILES = 'UPLOAD_FILES'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'

export const FETCH_TICKET = 'FETCH_TICKET'
export const CLOSE_TICKET = 'CLOSE_TICKET'
export const OPEN_TICKET = 'OPEN_TICKET'
export const TICKET_CLOSED = 'TICKET_CLOSED'
export const TICKET_OPENED = 'TICKET_OPENED'

export const FETCH_TICKET_LIST = 'FETCH_TICKET_LIST'
export const NEW_TICKET = 'NEW_TICKET'

export const FETCH_UPLOAD = 'FETCH_UPLOAD'
export const RE_CREDIT_UPLOAD = 'RE_CREDIT_UPLOAD'
export const PLACE_UPLOAD_ON_HOLD = 'PLACE_UPLOAD_ON_HOLD'
export const UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS'
export const UPLOAD_READY = 'UPLOAD_READY'
export const UPLOAD_DOWNLOADED = 'UPLOAD_DOWNLOADED'
export const UPLOAD_RE_CREDITED = 'UPLOAD_RE_CREDITED'
export const UPLOAD_ON_HOLD = 'UPLOAD_ON_HOLD'

export const FETCH_UPLOAD_ADMIN_NOTES = 'FETCH_UPLOAD_ADMIN_NOTES'
export const EDIT_UPLOAD_ADMIN_NOTES = 'EDIT_UPLOAD_ADMIN_NOTES'
export const UPLOAD_ADMIN_NOTES_EDITED = 'UPLOAD_ADMIN_NOTES_EDITED'

export const FETCH_UPLOAD_LIST = 'FETCH_UPLOAD_LIST'
export const UPLOAD_STATUS_CHANGED = 'UPLOAD_STATUS_CHANGED'
export const NEW_UPLOAD = 'NEW_UPLOAD'

export const FETCH_DEALER_LIST = 'FETCH_DEALER_LIST'
export const FETCH_DEALER = 'FETCH_DEALER'
export const CHANGE_DEALER_STATUS = 'CHANGE_DEALER_STATUS'
export const DEALER_STATUS_CHANGED = 'DEALER_STATUS_CHANGED'
export const CHANGE_DEALER_CREDIT = 'CHANGE_DEALER_CREDIT'
export const DEALER_CREDIT_CHANGED = 'DEALER_CREDIT_CHANGED'
export const EDIT_DEALER_CREDIT_PACKAGE = 'EDIT_DEALER_CREDIT_PACKAGE'
export const NEW_DEALER_CREDIT_PACKAGE = 'NEW_DEALER_CREDIT_PACKAGE'
export const DELETE_DEALER_CREDIT_PACKAGE = 'DELETE_DEALER_CREDIT_PACKAGE'
export const NEW_DEALER = 'NEW_DEALER'

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION'
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'
export const NOTIFICATION_READ = 'NOTIFICATION_READ'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS'

export const FETCH_STATUS = 'FETCH_STATUS'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const STATUS_UPDATED = 'STATUS_UPDATED'

export const FETCH_SYSTEM = 'FETCH_SYSTEM'
export const SYSTEM_UPDATED = 'SYSTEM_UPDATED'
export const UPDATE_SYSTEM = 'UPDATE_SYSTEM'

export const FETCH_AUTO_POP_UP = 'FETCH_AUTO_POP_UP'
export const FETCH_DAILY_UPLOAD_COUNT = 'FETCH_DAILY_UPLOAD_COUNT'
