import React, { useEffect } from 'react'
import _ from 'lodash'

import history from '../history'
import SignupTemplate from '../templates/SignupTemplate'

const SignupPending = ({ location }) => {
  const membership = _.get(location, 'state.membership')

  useEffect(() => {
    if (!membership) {
      history.push('/login')
    }
  }, [membership])

  if (!membership) {
    return null
  }

  return (
    <SignupTemplate title="We're reviewing your account">
      <p className="text-gray-600 text-base">
        Our team is reviewing the information you provided us. We check every
        account request to prevent malicious use of our systems and to keep our
        service limited to authorised DK Tuning dealers.
      </p>
      <p className="mt-4 text-gray-600">
        This process usually doesn't take long. If we need any further
        information we'll be in touch using the email you provided us, which is{' '}
        <strong className="underline">{membership.user.email}</strong>.
        Otherwise, we'll let you know your account has been approved and you'll
        be free to use the system!
      </p>
    </SignupTemplate>
  )
}

export default SignupPending
