import React, { useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'

import AuthContext from '../context/AuthContext'
import logo from '../assets/logos/logo.png'
import logo1x from '../assets/logos/logo@1,5x.png'
import logo2x from '../assets/logos/logo@2x.png'
import logo3x from '../assets/logos/logo@3x.png'

const SignupTemplate = (props) => {
  const { children, title } = props

  const [auth] = useContext(AuthContext)

  if (auth.isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <div className="bg-white min-h-screen md:flex md:overflow-hidden">
      <div>
        <div className="bg-green-600 h-2 w-full" />
      </div>
      <div className="px-4 flex mx-auto pb-6 pt-12 justify-center w-full md:min-w-[550px] md:flex-1 md:py-[calc(5vh + 40px)] md:px-10 md:pt-20">
        <div className="flex-1 max-w-[550px]">
          <img
            alt="DK Tuning logo"
            src={logo}
            srcSet={`${logo1x} 1.5x, ${logo2x} 2x, ${logo3x} 3x`}
            className="h-20"
          />
          <h1 className="text-xl font-bold tracking-tight pt-5 pb-2 sm:text-2xl sm:pt-8 sm:pb-4">
            {title}
          </h1>
          <div>{children}</div>
          <div className="pt-8 space-x-3 text-center sm:pt-24">
            <Link className="text-gray-600 text-xs hover:underline" to="/terms">
              Terms
            </Link>
            <Link
              className="text-gray-600 text-xs hover:underline"
              to="/privacy-policy"
            >
              Privacy
            </Link>
            <span className="text-gray-600 text-xs">
              © DK Tuning Ltd {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
      <span className="bg-[url('/src/assets/jpg/signup-image.jpg')] bg-cover bg-no-repeat bg-right hidden md:block md:flex-1" />
    </div>
  )
}

export default SignupTemplate
