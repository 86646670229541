import React from 'react'

import Button from '../../components/Button'
import useLocalStorage from '../../hooks/useLocalStorage'

const API_URL = import.meta.env.VITE_API_URL
const REPORT_ROUTE = '/admin/reports/vehicles'
const endpoint = `${API_URL}${REPORT_ROUTE}?`

const VehicleListReport = () => {
  const [getAuthToken] = useLocalStorage('authToken')
  const jwt = getAuthToken()

  const url = `${endpoint}jwt=${jwt}`

  return (
    <div>
      <Button color="green" as="a" href={url} target="_blank">
        Download CSV
      </Button>
    </div>
  )
}

export default VehicleListReport
