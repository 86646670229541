import React from 'react'

import FileList from '../../FileList'

const ViewUploadFiles = (props) => {
  const { upload, setUpload, socket, onError, isAdmin } = props

  if (isAdmin) {
    return (
      <div className="divide-y divide-gray-200">
        <FileList
          isAdmin
          description="Files uploaded by the dealer"
          files={upload.files}
          heading="Dealer files"
          noFilesText="The dealer has not uploaded any files."
          socket={socket}
          isUpload
        />
        <FileList
          isAdmin
          isAdminFileList
          description="Files uploaded by DK Tuning Support"
          files={upload.files}
          heading="DK Tuning files"
          noFilesText="We have not uploaded any files to this upload."
          onNewFiles={(files) => setUpload({ files })}
          onNewFilesError={onError}
          socket={socket}
          isUpload
        />
      </div>
    )
  }

  return (
    <div className="divide-y divide-gray-200">
      <FileList
        description="Files uploaded by you or your team"
        files={upload.files}
        heading="Dealer files"
        noFilesText="You have not uploaded any files."
        onNewFiles={(files) => setUpload({ files })}
        onNewFilesError={onError}
        socket={socket}
        isUpload
      />
      <FileList
        isAdminFileList
        description="Files uploaded by DK Tuning Support"
        files={upload.files}
        heading="DK Tuning files"
        noFilesText="DK Tuning Support have not uploaded any files to this upload."
        socket={socket}
        isUpload
      />
    </div>
  )
}

export default ViewUploadFiles
