import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import {
  faFireAlt,
  faWrench,
  faBoxFull,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCar,
  faCalendarAlt,
  faAngleRight,
} from '@fortawesome/pro-light-svg-icons'

import Label from '../../components/Label'
import Badge from '../../components/Badge'
import { toCapitalCase } from '../../utils/strings'
import {
  getStatus,
  getUploadLabelColor,
  getTune,
  getTuneIcon,
  getVehicleTypeIcon,
} from '../../utils/uploads'

const RecentUploadItem = ({ upload, first, last }) => {
  const renderVehicleReference = () => {
    if (upload.vrm) {
      return upload.vrm
    }

    if (upload.reference) {
      return upload.reference
    }

    return 'Manual'
  }

  return (
    <Link
      className={cn(
        'bg-white block border-gray-100 relative hover:bg-gray-50',
        {
          'rounded-tl-md rounded-tr-md': first,
          'roounded-bl-md rounded-mr-md': last,
          'border-b-4': !last,
        }
      )}
      to={{
        pathname: `/uploads/view/${upload.id}`,
        state: { upload },
      }}
    >
      <div className="flex space-x-3 pr-3 pl-7 pt-4 pb-3 relative">
        <div className="min-w-[260px] flex-1">
          <p className="text-green-600 text-sm mb-1.5 h-5 leading-5 font-medium">
            {upload.vehicleType === 'CAR' && (
              <>
                {upload.vehicle.make} {upload.vehicle.model}{' '}
                {upload.vehicle.startYear}
              </>
            )}
            {upload.vehicleType !== 'CAR' && (
              <>
                {upload.manualVehicle.make} {upload.manualVehicle.model}{' '}
                {upload.manualVehicle.year}
              </>
            )}
          </p>
          <p className="text-gray-600 text-xs">
            <FontAwesomeIcon
              className="text-gray-500 mr-2"
              icon={faCar}
              size="lg"
            />
            {renderVehicleReference()} •{' '}
            {upload.vehicleType === 'CAR' && (
              <>
                {toCapitalCase(upload.vehicle.fuel || '')} •{' '}
                {upload.vehicle.engineSize}cc
              </>
            )}
            {upload.vehicleType !== 'CAR' && (
              <>
                {upload.manualVehicle.hpModel} •{' '}
                {upload.manualVehicle.engineSize}
              </>
            )}
          </p>
        </div>
        <div className="min-w-[260px] flex-[2]">
          <p className="text-gray-900 mb-1.5 h-5 leading-5 text-sm">
            Upload number {upload.uploadNumber}
            <Label className="ml-2" color={getUploadLabelColor(upload.status)}>
              {getStatus(upload.status)}
            </Label>
          </p>
          <p className="text-gray-600 text-xs">
            <FontAwesomeIcon
              className="text-gray-500 mr-2"
              icon={faCalendarAlt}
              size="lg"
            />
            Uploaded{' '}
            {format(new Date(upload.createdAt), "dd MMMM yyy 'at' hh:mma")}
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            className="absolute right-5 mt-1 top-1/2 -translate-y-1/2 text-gray-400"
            icon={faAngleRight}
            size="lg"
          />
        </div>
      </div>
      <div className="border-dashed border-t border-gray-200 py-2 ml-7 mr-5 flex items-center justify-start space-x-2">
        {upload.priority && (
          <Badge icon={faFireAlt} color="pink">
            Priority file
          </Badge>
        )}
        <Badge icon={getVehicleTypeIcon(upload.vehicleType)} color="gray">
          {toCapitalCase(upload.vehicleType)}
        </Badge>
        <Badge icon={getTuneIcon(upload.tune.tuneRequired)} color="gray">
          {getTune(upload.tune.tuneRequired)}
        </Badge>
        <Badge
          icon={upload.uploadType === 'FULL' ? faBoxFull : faWrench}
          color="gray"
        >
          {upload.uploadType === 'FULL' ? 'Full' : 'Adjustment'} remap
        </Badge>
      </div>
    </Link>
  )
}

export default RecentUploadItem
