import React from 'react'
import cn from 'classnames'

const TextButton = (props) => {
  const {
    theme = 'primary',
    className,
    children,
    disabled,
    size = 'sm',
    as: Component = 'button',
    ...rest
  } = props

  const buttonCn = cn('font-medium rounded-md', {
    'text-xs': size === 'xs',
    'text-sm': size === 'sm',
    'hover:underline focus:outline-none focus:underline': !disabled,
    'text-green-600 focus:ring-green-600': theme === 'primary' && !disabled,
    'text-gray-500 focus:ring-green-600': theme === 'secondary' && !disabled,
    'text-red-600 focus:ring-red-600': theme === 'danger' && !disabled,
    'text-gray-300': disabled,
    [className]: className,
  })

  return (
    <Component className={buttonCn} type="button" disabled={disabled} {...rest}>
      {children}
    </Component>
  )
}

export default TextButton
