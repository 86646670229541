import React from 'react'

import Dialog from '../../../components/Dialog'

const CheckoutChangeMethodModal = (props) => {
  const { isOpen, setIsOpen, method, onConfirm } = props

  const handleOnConfirm = () => {
    onConfirm()
    setIsOpen()
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Change payment method"
      buttonProps={{
        onClick: handleOnConfirm,
        children: 'Confirm',
        color: 'green',
      }}
    >
      {isOpen && (
        <p className="mt-2 text-gray-600 text-sm">
          <strong>
            Change payment method to {method.card.brand.toUpperCase()} ending in{' '}
            {method.card.last4}?
          </strong>
        </p>
      )}
    </Dialog>
  )
}

export default CheckoutChangeMethodModal
