import React, { useState, useEffect } from 'react'

import Button from '../Button'
import usePrivateApi from '../../hooks/usePrivateApi'
import InputGroup from '../InputGroup'

const VrmLookup = (props) => {
  const { checked, onVehicleChange, hideDetails } = props

  const [vrm, setVrm] = useState('')
  const [vrmError, setVrmError] = useState(null)
  const [generalError, setGeneralError] = useState(null)
  const [
    { res, success, error, isLoading },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  useEffect(() => {
    if (success) {
      const { vehicle, otherVehicles, vrm } = res.data
      onVehicleChange({ vehicle, otherVehicles, vrm })
      setVrmError(null)
      setGeneralError(null)
      resetSuccess()
    }
  }, [success, onVehicleChange, res, resetSuccess, resetError])

  useEffect(() => {
    if (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setVrmError('No vehicle found.')
        } else {
          setGeneralError('An error occurred.')
        }
      } else {
        setGeneralError('An error occurred')
      }

      resetError()
    }
  }, [error, resetError])

  const handleVrmLookup = (e) => {
    e.preventDefault()
    sendRequest({ url: `/vehicles/vrm/${vrm}` })
  }

  if (!checked || hideDetails) {
    return null
  }

  return (
    <>
      <form
        className="mt-2 pb-2 sm:mb-0 sm:mt-3 sm:space-x-3 sm:flex sm:items-end"
        onSubmit={handleVrmLookup}
      >
        <InputGroup
          className="mb-4 sm:mb-0 sm:flex-1 sm:max-w-sm"
          label="Registration number"
          id="reg"
          disabled={isLoading}
          onChange={(e) => setVrm(e.target.value.toUpperCase())}
          value={vrm}
        />
        <Button color="green" disabled={isLoading} type="submit">
          {isLoading ? 'Searching...' : 'Search vehicle'}
        </Button>
      </form>
      {vrmError && <p className="text-red-600 mt-3 mb-4 text-sm">{vrmError}</p>}
      {generalError && <p className="text-red-600 mt-3 my-4">{generalError}</p>}
    </>
  )
}

export default VrmLookup
