import React, { useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'

import Alert from '../components/Alert'
import AuthContext from '../context/AuthContext'
import logo from '../assets/logos/logo.png'
import logo1x from '../assets/logos/logo@1,5x.png'
import logo2x from '../assets/logos/logo@2x.png'
import logo3x from '../assets/logos/logo@3x.png'

const AuthTemplate = (props) => {
  const { children, title, alertSuccess, alertError } = props

  const [auth] = useContext(AuthContext)

  if (auth.isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center pt-28">
      <img
        alt="DK Tuning logo"
        src={logo}
        srcSet={`${logo1x} 1.5x, ${logo2x} 2x, ${logo3x} 3x`}
        className="h-18 w-auto block"
      />
      <h1 className="text-2xl font-bold tracking-tight pt-8 pb-6">{title}</h1>
      {alertSuccess && (
        <Alert className="mb-4 border" success>
          {alertSuccess}
        </Alert>
      )}
      {alertError && <Alert error>{alertError}</Alert>}
      <div className="border border-gray-200 bg-white px-8 w-full sm:w-96 py-6 rounded-lg shadow">
        {children}
      </div>
      <div className="space-x-3 py-6">
        <Link className="text-xs text-gray-600 hover:underline" to="/terms">
          Terms
        </Link>
        <Link
          className="text-xs text-gray-600 hover:underline"
          to="/privacy-policy"
        >
          Privacy
        </Link>
        <span className="text-xs text-gray-600">
          © DK Tuning Ltd {new Date().getFullYear()}
        </span>
      </div>
    </div>
  )
}

export default AuthTemplate
