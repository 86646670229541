import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-gray-100 border-t border-gray-200 py-4">
      <div className="max-w-5xl mx-auto px-4 space-y-6">
        <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-6">
          <div className="text-xs flex-1">
            <h6 className="font-medium text-gray-700 mb-1">File times (UK)</h6>
            <div className="text-gray-600 space-y-1">
              <p>Monday-Friday: 7am-10pm (up to 1 hour turnaround after 6pm)</p>
              <p>Saturday: 8am-5:30pm</p>
              <p>Sunday: 12pm-4pm (up to 1 hour turnaround)</p>
            </div>
          </div>
          <div className="text-xs flex-1">
            <h6 className="font-medium text-gray-700 mb-1">
              Support times (UK)
            </h6>
            <div className="text-gray-600 space-y-1">
              <p>Monday-Friday: 9am-6pm</p>
              <p>Saturday: 9am-5:30pm</p>
              <p>Sunday: 12pm-4pm (up to 1 hour turnaround)</p>
            </div>
          </div>
        </div>
        <div>
          <a
            className="inline-block text-xs text-green-600 my-2 leading-none sm:my-1 hover:underline"
            href={import.meta.env.VITE_TERMS_PDF_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and conditions (PDF)
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
