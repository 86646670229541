import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
  faCalendarAlt,
  faAngleRight,
  faUserCircle,
} from '@fortawesome/pro-light-svg-icons'

import Label from '../../Label'
import {
  getStatus,
  getCategory,
  getStatusLabelColor,
} from '../../../utils/support'

const TicketListItem = ({ ticket, page, pathname, isAdmin, filters }) => {
  const boxCn = cn(
    'border-b border-gray-200 relative flex space-x-3 py-4 pr-3 pl-7 hover:bg-gray-50'
  )

  const leftCn = cn('min-w-[260px]', {
    'flex-1': !isAdmin,
    'flex-[3]': isAdmin,
  })

  const middleCn = cn('min-w-[260px]', {
    'flex-[2]': !isAdmin,
    'flex-[3]': isAdmin,
  })

  const rightCn = cn('min-w-[260px]', {
    'flex-[2]': isAdmin,
  })

  return (
    <Link
      className={boxCn}
      to={{
        pathname: `/support/view/${ticket.id}`,
        state: {
          ticket,
          listState: {
            page,
            pathname,
            filters,
          },
        },
      }}
    >
      <div className={leftCn}>
        <p
          className={cn('text-black text-sm mb-1', {
            'font-medium': !ticket.isUnreplied,
            'font-semibold': ticket.isUnreplied,
          })}
        >
          {ticket.subject}
        </p>
        <p className="text-gray-600 text-xs">
          <FontAwesomeIcon
            className="text-gray-500 mr-2"
            icon={faInfoCircle}
            size="lg"
          />
          {getCategory(ticket.category)}
        </p>
      </div>
      <div className={middleCn}>
        <p
          className={cn('text-gray-900 mb-1.5 text-sm', {
            'font-medium': ticket.isUnreplied,
          })}
        >
          Ticket number {ticket.ticketNumber}
          <Label color={getStatusLabelColor(ticket.status)} className="ml-2">
            {getStatus(ticket.status)}
          </Label>
        </p>
        <p className="text-gray-600 text-xs">
          <FontAwesomeIcon
            className="text-gray-500 mr-2"
            icon={faCalendarAlt}
            size="lg"
          />
          Created{' '}
          {format(new Date(ticket.createdAt), "dd MMMM yyy 'at' hh:mma")}
        </p>
      </div>
      {isAdmin && (
        <div className={rightCn}>
          <p
            className={cn('text-gray-900 mb-1.5 text-sm mr-4', {
              'font-medium': ticket.isUnreplied,
            })}
          >
            {ticket.createdBy.dealer.name}
          </p>
          <p className="text-gray-600 text-xs">
            <FontAwesomeIcon
              className="text-gray-500 mr-2"
              icon={faUserCircle}
              size="lg"
            />
            DK number {ticket.createdBy.dealer.dkNumber}
          </p>
        </div>
      )}
      <div>
        <FontAwesomeIcon
          className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-400"
          size="lg"
          icon={faAngleRight}
        />
        {ticket.isUnreplied && (
          <span className="absolute my-2 left-0 top-0 bottom-0 w-1 bg-green-600 rounded-tr-md rounded-br-md" />
        )}
      </div>
    </Link>
  )
}

export default TicketListItem
