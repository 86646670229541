import React from 'react'
import currency from 'currency.js'
import { Link } from 'react-router-dom'

const CheckoutReviewPurchase = (props) => {
  const {
    purchase,
    subtotal,
    vatTotal,
    finalTotal,
    shouldChargeVat,
    discount,
  } = props

  const netAmount = currency(subtotal).divide(100).format()
  const vatAmount = currency(vatTotal).divide(100).format()
  const totalAmount = currency(finalTotal).divide(100).format()

  return (
    <div className="mt-8 sm:mt-4">
      <h3 className="text-xl font-semibold tracking-tight mb-4">
        Review purchase
      </h3>
      <div className="bg-white rounded-md border border-gray-200">
        <div className="px-6 py-6 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:items-center sm:space-x-3">
            <p className="text-base font-medium">
              {purchase.creditPackage.name}
            </p>
            {purchase.saving !== 0 && (
              <p className="text-green-600 font-medium text-sm">
                You're saving {purchase.saving}%!
              </p>
            )}
          </div>
          {discount && (
            <p className="block text-sm text-gray-600">
              {discount.code} - {discount.percent}% off
            </p>
          )}
          <div className="mt-2 sm:mt-0">
            <p className="font-medium text-sm">£{netAmount}</p>
          </div>
        </div>
        <div className="px-6 py-4 flex flex-col-reverse sm:space-x-4 sm:items-center sm:justify-between sm:flex-row">
          <div className="mt-6 sm:mt-0 sm:flex-1">
            {shouldChargeVat ? (
              <>
                <h5 className="text-sm font-medium">
                  Why am I being charged VAT?
                </h5>
                <p className="text-gray-600 text-xs mt-1">
                  We are legally required to charge VAT to all businesses based
                  inside the UK. We must also charge VAT to EU businesses that
                  do not hold a valid VAT number. If you are based inside the EU
                  (but not in the UK) and hold a valid VAT number, enter it into{' '}
                  <Link
                    className="text-green-600 underline"
                    to="/settings/billing"
                  >
                    your billing settings
                  </Link>{' '}
                  to remove the VAT on this and all future purchases.
                </p>
              </>
            ) : (
              <>
                <h5 className="text-sm font-medium">
                  There is no VAT on this purchase
                </h5>
                <p className="text-gray-600 text-xs mt-1">
                  We are not charging VAT on this purchase as you are either
                  based outside the EU or you are based inside the EU (but
                  outside the UK) and have verified your VAT number with us.
                </p>
              </>
            )}
          </div>
          <div className="flex-1 text-right space-y-2">
            <div className="flex items-center justify-between sm:justify-end space-x-8">
              <p className="text-sm text-gray-600">Subtotal</p>
              <p className="text-sm text-gray-600 sm:w-20">£{netAmount}</p>
            </div>
            {shouldChargeVat && (
              <div className="flex items-center justify-between sm:justify-end space-x-8">
                <p className="text-sm text-gray-600">UK VAT at 20%</p>
                <p className="text-sm text-gray-600 sm:w-20">£{vatAmount}</p>
              </div>
            )}
            <div className="flex items-center justify-between sm:justify-end space-x-8">
              <p className="text-gray-900 font-medium">Total to be paid</p>
              <p className="text-gray-900 font-medium sm:w-20">
                £{totalAmount}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutReviewPurchase
