import React, { useState, useContext } from 'react'
import cn from 'classnames'

import AuthContext from '../../../context/AuthContext'
import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import InputGroup from '../../../components/InputGroup'

const SubdealerTransferModal = ({ isOpen, setIsOpen, subdealer }) => {
  const [auth] = useContext(AuthContext)
  const [amount, setAmount] = useState(0)
  const [amountStr, setAmountStr] = useState('')

  const { dealer } = auth.membership

  const masterBalance = dealer.credit.balance
  const newMasterBalance = masterBalance - amount
  const subdealerBalance = subdealer.credit.balance
  const newSubdealerBalance = subdealerBalance + amount

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: '/settings/credits/transfer',
      method: 'post',
      data: { amount, subdealerId: subdealer.id },
    })
  }

  const handleChange = (e) => {
    // Remove everything except 0-9
    const str = e.target.value.replace(/^[^+-][^0-9]+$/g, '')
    const int = parseInt(str, 10)
    setAmountStr(str)
    setAmount(int || 0)
  }

  const renderBalance = (label, amount) => (
    <div
      className={cn('text-sm flex items-center justify-between', {
        'text-red-600': amount < 0,
        'text-gray-700': amount >= 0,
      })}
    >
      <p>{label}:</p>{' '}
      <p className="font-medium">
        {amount} credit{amount !== 1 && amount !== -1 && 's'}
      </p>
    </div>
  )

  const renderError = () => {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          if (error.response.data.balance) {
            return (
              <p className="text-gray-600 text-sm mt-2">
                {error.response.data.balance}
              </p>
            )
          }
        }
      }

      return (
        <p className="text-gray-600 text-sm mt-2">
          Could not transfer credits. Please try again later.
        </p>
      )
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        {renderError()}
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Credits have been transferred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          You have transferred {Math.abs(amount)} credits{' '}
          {amount > 0 ? 'to' : 'from'} {subdealer.name}.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Transfer credits"
      buttonProps={{
        disabled:
          isLoading ||
          !amount ||
          newMasterBalance < 0 ||
          newSubdealerBalance < 0,
        children: isLoading ? 'Transferring...' : 'Transfer',
        color: 'green',
        onClick: handleSubmit,
      }}
    >
      <div className="space-y-4 mt-2">
        <InputGroup
          required
          className="max-w-xs"
          inputClassName="text-right pr-16"
          trailingAddon="credits"
          label="Amount to transfer"
          disabled={isLoading}
          onChange={handleChange}
          value={amountStr}
        />

        <div className="rounded-md bg-gray-100 px-4 py-3">
          <h5 className="text-xs font-medium tracking-tight uppercase text-gray-900 mb-1">
            New balances
          </h5>
          {renderBalance('Your balance', newMasterBalance)}
          {renderBalance(subdealer.name, newSubdealerBalance)}
        </div>
      </div>
    </Dialog>
  )
}

export default SubdealerTransferModal
