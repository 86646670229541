import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArchive,
  faAt,
  faBan,
  faEllipsisV,
  faExchange,
  faShieldCheck,
  faUserSlash,
} from '@fortawesome/pro-regular-svg-icons'

import SubdealerArchiveModal from './ModalArchive'
import SubdealerPlaceOnHoldModal from './ModalPlaceOnHold'
import SubdealerTransferModal from './ModalTransferCredits'
import SubdealerResendInviteModal from './ModalResendInvite'
import SubdealerCancelInviteModal from './ModalCancelInvite'
import SubdealerReactivateModal from './ModalReactivate'
import Dropdown from '../../../components/Dropdown'

const SubdealersItem = ({ subdealer }) => {
  const { name, dkNumber, status, credit } = subdealer

  const [holdModalOpen, setHoldModalOpen] = useState(false)
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const [transferModalOpen, setTransferModalOpen] = useState(false)
  const [reactivateModalOpen, setReactivateModalOpen] = useState(false)
  const [resendInviteModalOpen, setResendInviteModalOpen] = useState(false)
  const [cancelInviteModalOpen, setCancelInviteModalOpen] = useState(false)

  const renderStatus = () => {
    if (status === 'PENDING') {
      return <p className="text-yellow-600">Pending</p>
    }
    if (status === 'ON_HOLD') {
      return <p className="text-red-600">On hold</p>
    }

    if (status === 'ARCHIVED') {
      return <p className="text-gray-600">Archived</p>
    }

    return ''
  }

  const renderOptions = () => {
    const options = []

    const transfer = {
      onClick: () => setTransferModalOpen(true),
      label: 'Transfer credits',
      icon: faExchange,
    }

    const placeOnHold = {
      onClick: () => setHoldModalOpen(true),
      label: 'Place on hold',
      icon: faBan,
    }

    const archive = {
      onClick: () => setArchiveModalOpen(true),
      label: 'Archive account',
      icon: faArchive,
    }

    const reactivate = {
      onClick: () => setReactivateModalOpen(true),
      label: 'Re-activate account',
      icon: faShieldCheck,
    }

    const resendInvite = {
      onClick: () => setResendInviteModalOpen(true),
      label: 'Resend invitiation',
      icon: faAt,
    }

    const cancelInvite = {
      onClick: () => setCancelInviteModalOpen(true),
      label: 'Cancel invitiation',
      icon: faUserSlash,
    }

    switch (status) {
      case 'ACTIVE': {
        options.push(transfer)
        options.push(placeOnHold)
        options.push(archive)
        break
      }

      case 'ON_HOLD': {
        options.push(transfer)
        options.push(reactivate)
        options.push(archive)
        break
      }

      case 'ARCHIVED': {
        options.push(transfer)
        options.push(reactivate)
        options.push(placeOnHold)
        break
      }

      case 'PENDING': {
        options.push(resendInvite)
        options.push(cancelInvite)
        break
      }

      default: {
        return
      }
    }

    // Don't show options button if no actions can be made
    if (options.length > 0) {
      return (
        <Dropdown
          items={options}
          renderButton={({ MenuButton }) => (
            <MenuButton className="rounded-full block p-0 ml-4 h-8 w-8 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-600 hover:bg-gray-100">
              <FontAwesomeIcon
                icon={faEllipsisV}
                size="1x"
                className="text-gray-600"
              />
            </MenuButton>
          )}
        />
      )
    }
  }

  return (
    <div className="flex-1 rounded-md shadow-sm border border-gray-200 px-4 py-3 flex items-center justify-between">
      <div>
        <p className="font-medium tracking-tight text-base">
          {name} {`(${dkNumber})`}
        </p>
        <p className="text-gray-600 text-xs">
          Credit balance: {credit.balance}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-xs uppercase font-medium">{renderStatus()}</span>
        <div>{renderOptions()}</div>
      </div>
      <SubdealerCancelInviteModal
        isOpen={cancelInviteModalOpen}
        setIsOpen={() => setCancelInviteModalOpen(false)}
        subdealer={subdealer}
      />
      <SubdealerResendInviteModal
        isOpen={resendInviteModalOpen}
        setIsOpen={() => setResendInviteModalOpen(false)}
        subdealer={subdealer}
      />
      <SubdealerTransferModal
        isOpen={transferModalOpen}
        setIsOpen={() => setTransferModalOpen(false)}
        subdealer={subdealer}
      />
      <SubdealerPlaceOnHoldModal
        isOpen={holdModalOpen}
        setIsOpen={() => setHoldModalOpen(false)}
        subdealer={subdealer}
      />
      <SubdealerArchiveModal
        isOpen={archiveModalOpen}
        setIsOpen={() => setArchiveModalOpen(false)}
        subdealer={subdealer}
      />
      <SubdealerReactivateModal
        isOpen={reactivateModalOpen}
        setIsOpen={() => setReactivateModalOpen(false)}
        subdealer={subdealer}
      />
    </div>
  )
}

export default SubdealersItem
