import React from 'react'

import history from '../../../history'
import Dialog from '../../../components/Dialog'
import Button from '../../../components/Button'

const CheckoutSuccessModal = (props) => {
  const { isOpen } = props

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => history.push('/dashboard')}
      title="Purchase successful"
      buttonProps={null}
    >
      <p className="mt-2 text-gray-600 text-sm">
        Thank you for your purchase. Your credits have been added to your
        account. You can download an invoice for this purchase from your billing
        settings.
      </p>
      <div className="mt-4 flex space-x-4">
        <Button color="green" onClick={() => history.push('/uploads/new')}>
          Create an upload
        </Button>
        <Button color="white" onClick={() => history.push('/dashboard')}>
          Go to dashboard
        </Button>
      </div>
    </Dialog>
  )
}

export default CheckoutSuccessModal
