import React from 'react'

import Button from '../components/Button'
import InputGroup from '../components/InputGroup'
import TextButton from '../components/TextButton'
import { getCountryOptions } from '../utils/address'

const SubdealerDealer = (props) => {
  const {
    isLoading,
    businessName,
    setBusinessName,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    addressCity,
    setAddressCity,
    addressRegion,
    setAddressRegion,
    addressPostcode,
    setAddressPostcode,
    addressCountry,
    setAddressCountry,
    onPrevious,
    onSubmit,
    generalError,
  } = props

  const btnDisabled =
    businessName === '' ||
    addressLine1 === '' ||
    addressPostcode === '' ||
    isLoading

  return (
    <form onSubmit={onSubmit}>
      <h2 className="text-lg md:text-xl font-medium tracking-tight">
        Business name and address
      </h2>
      {generalError && (
        <p className="text-red-600 text-sm mt-3">{generalError}</p>
      )}
      <div className="space-y-4 mt-4">
        <InputGroup
          autoFocus
          required
          disabled={isLoading}
          label="Business name"
          id="businessName"
          onChange={(e) => setBusinessName(e.target.value)}
          value={businessName}
        />
        <div className="space-y-4 mt-4 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            required
            className="flex-1"
            disabled={isLoading}
            label="Address line one"
            id="addressLineOne"
            onChange={(e) => setAddressLine1(e.target.value)}
            value={addressLine1}
          />
          <InputGroup
            className="flex-1"
            disabled={isLoading}
            label="Address line two"
            id="addressLineTwo"
            onChange={(e) => setAddressLine2(e.target.value)}
            value={addressLine2}
          />
        </div>
        <div className="space-y-4 mt-4 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            className="flex-1"
            disabled={isLoading}
            label="City/town"
            id="city"
            onChange={(e) => setAddressCity(e.target.value)}
            value={addressCity}
          />
          <InputGroup
            className="flex-1"
            disabled={isLoading}
            label="Region"
            id="region"
            onChange={(e) => setAddressRegion(e.target.value)}
            value={addressRegion}
          />
        </div>
        <div className="space-y-4 mt-4 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            required
            className="flex-1"
            disabled={isLoading}
            label="Postcode"
            id="postcode"
            onChange={(e) => setAddressPostcode(e.target.value.toUpperCase())}
            value={addressPostcode}
          />
          <InputGroup
            required
            className="flex-1"
            type="select"
            disabled={isLoading}
            label="Country"
            id="country"
            onChange={(e) => setAddressCountry(e.target.value)}
            renderOptions={getCountryOptions}
            value={addressCountry}
          />
        </div>
      </div>
      <Button
        color="green"
        block
        className="mt-6"
        size="xl"
        disabled={btnDisabled}
        type="submit"
      >
        {isLoading ? 'Saving...' : 'Confirm details'}
      </Button>
      <TextButton
        theme="secondary"
        className="mt-8 mx-auto sm:mt-12"
        size="xs"
        onClick={onPrevious}
      >
        ⟵ Change name and login details
      </TextButton>
    </form>
  )
}

export default SubdealerDealer
