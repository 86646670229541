import React from 'react'
import cn from 'classnames'
import { Disclosure } from '@headlessui/react'

const CheckBox = (props) => {
  const {
    checked,
    hideDetails,
    onChange,
    disabled,
    label,
    description,
    renderContent,
  } = props

  return (
    <Disclosure
      as="div"
      className={cn('border rounded-md focus:outline-none', {
        'bg-green-50 border-transparent z-10 ring-2 ring-green-500': checked,
        'bg-white border-gray-300 hover:bg-gray-50': !checked,
      })}
    >
      {() => (
        <>
          <Disclosure.Button
            className={cn(
              'py-3 pl-4 pr-8 flex-1 cursor-pointer w-full text-left rounded-md focus:outline-none',
              {
                'focus:ring-2 focus:ring-green-500': !checked,
              }
            )}
            onClick={onChange}
            disabled={disabled}
          >
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={checked}
                readOnly
                tabIndex="-1"
                className="h-5 w-5 rounded-md text-green-600 border-gray-300"
              />
              <p className="font-medium text-sm tracking-tight text-gray-900">
                {label}
              </p>
            </div>
            {description && (
              <p className="mt-1 ml-7 text-gray-600 text-sm">{description}</p>
            )}
          </Disclosure.Button>
          {checked && !hideDetails && renderContent && (
            <Disclosure.Panel static className="px-4 pb-4">
              {renderContent}
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  )
}

export default CheckBox
