import { useState, useEffect, useCallback } from 'react'
import io from 'socket.io-client'

import useLocalStorage from './useLocalStorage'
import { AUTH, JOIN_ROOM, LEAVE_ROOM } from '../utils/sockets'

const API_URL = import.meta.env.VITE_API_URL

const getSocket = (namespace) => {
  return io(`${API_URL}${namespace}`, {
    transports: ['websocket'],
  })
}

const usePrivateSocket = (namespace, roomId) => {
  const [isAuthed, setIsAuthed] = useState(false)
  const [inRoom, setInRoom] = useState(false)
  const [connErr, setConnErr] = useState(false)
  const [currentRoomId, setCurrentRoomId] = useState(roomId || null)
  const [err, setErr] = useState(null)
  const [getAuthToken] = useLocalStorage('authToken')
  const [socket, setSocket] = useState(null)

  const joinRoom = useCallback(
    (roomId) => {
      if (!roomId) {
        throw new Error('roomId is required.')
      }

      socket.emit(JOIN_ROOM, roomId, (err, success) => {
        if (success) {
          setInRoom(true)
          setCurrentRoomId(roomId)
        } else {
          setErr(err)
        }
      })
    },
    [socket]
  )

  const resetErr = useCallback(() => setErr(null), [])

  useEffect(() => {
    setSocket(getSocket(namespace))
  }, [namespace])

  useEffect(() => {
    if (!isAuthed && socket) {
      socket.emit(AUTH, getAuthToken(), (err, success) => {
        if (success) {
          setConnErr(false)
          setIsAuthed(true)
        } else {
          setErr(err)
        }
      })
    }
  }, [isAuthed, getAuthToken, socket])

  useEffect(() => {
    if (roomId !== currentRoomId) {
      setCurrentRoomId(roomId)
      setIsAuthed(false)

      socket.emit(LEAVE_ROOM, (err, success) => {
        if (success) {
          setInRoom(false)
        } else {
          setErr(err)
        }
      })
    }
  }, [roomId, currentRoomId, socket])

  useEffect(() => {
    if (isAuthed && !inRoom && currentRoomId) {
      socket.emit(JOIN_ROOM, currentRoomId, (err, success) => {
        if (success) {
          setInRoom(true)
        } else {
          setErr(err)
        }
      })
    }
  }, [inRoom, isAuthed, socket, currentRoomId])

  useEffect(() => {
    if (inRoom) {
      socket.on('disconnect', () => {
        setIsAuthed(false)
        setInRoom(false)
        setConnErr(true)
      })

      return () => socket.off('disconnect')
    }
  })

  return [
    { inRoom, err, connErr },
    { joinRoom, socket, resetErr },
  ]
}

export default usePrivateSocket
