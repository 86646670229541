import React, { useState, useContext } from 'react'
import axios from 'axios'

import AuthContext from '../../../context/AuthContext'
import Button from '../../Button'
import ImageUploadModal from '../../ImageUploadModal'
import useLocalStorage from '../../../hooks/useLocalStorage'

const API_URL = import.meta.env.VITE_API_URL

const AccountAvatar = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isPreparing, setIsPreparing] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(null)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [auth, setAuth] = useContext(AuthContext)
  const [getAuthToken] = useLocalStorage('authToken')
  const authToken = getAuthToken()

  const { firstName, lastName, imageUrl } = auth.membership.user

  const getUserInitials = () => {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  const handleSave = async (canvasScaled) => {
    try {
      setIsUploading(true)
      setIsPreparing(true)

      const getRes = await axios({
        baseURL: API_URL,
        url: '/settings/avatar',
        method: 'get',
        headers: { Authorization: `Bearer ${authToken}` },
      })

      setIsPreparing(false)

      canvasScaled.toBlob(async (blob) => {
        await axios({
          url: getRes.data.url,
          data: blob,
          method: 'put',
          onUploadProgress: (e) => {
            setUploadProgress(Math.round((e.loaded * 100) / e.total))
          },
        })

        const patchRes = await axios({
          baseURL: API_URL,
          url: '/settings/avatar',
          method: 'patch',
          headers: { Authorization: `Bearer ${authToken}` },
          data: { key: getRes.data.key },
        })

        setSuccess(true)
        setIsUploading(false)

        setAuth({
          ...auth,
          membership: {
            ...auth.membership,
            user: {
              ...auth.membership.user,
              imageUrl: patchRes.data.url,
            },
          },
        })

        setModalOpen(false)
      })
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div>
      <div className="mt-8 sm:mt-6 flex items-end">
        {!imageUrl ? (
          <>
            <span className="rounded-full bg-green-300 h-12 w-12 text-sm flex items-center justify-center font-bold mr-4">
              {getUserInitials()}
            </span>
            <Button color="white" size="sm" onClick={() => setModalOpen(true)}>
              Add your photo
            </Button>
          </>
        ) : (
          <>
            <img
              className="bg-green-700 rounded-full h-12 w-12 mr-4"
              src={imageUrl}
              alt="Avatar"
            />
            <Button color="white" size="sm" onClick={() => setModalOpen(true)}>
              Change photo
            </Button>
          </>
        )}
      </div>
      <ImageUploadModal
        isOpen={modalOpen}
        isUploading={isUploading}
        isPreparing={isPreparing}
        uploadProgress={uploadProgress}
        handleSave={handleSave}
        error={error}
        success={success}
        setIsOpen={setModalOpen}
        btnText={isUploading ? 'Saving...' : 'Save photo'}
        title="Upload your photo"
        rounded
      />
    </div>
  )
}

export default AccountAvatar
