import React, { useState, useEffect, useCallback, useRef } from 'react'
import cn from 'classnames'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { faTimes, faUndo, faRedo } from '@fortawesome/pro-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { Line } from 'rc-progress'
import Slider from 'rc-slider'

import Dialog from './Dialog'
import Button from './Button'

const ImageUploadModal = (props) => {
  const {
    isOpen,
    isUploading,
    isPreparing,
    success,
    error,
    uploadProgress,
    handleSave,
    setIsOpen,
    btnText,
    title,
    rounded,
  } = props

  const [file, setFile] = useState(null)
  const [zoom, setZoom] = useState(0)
  const [rotate, setRotate] = useState(0)
  const fileRef = useRef()

  const handleNewAccepted = useCallback(
    (acceptedFiles) => setFile(acceptedFiles[0]),
    []
  )

  useEffect(() => {
    // When opening modal again after just selecting and saving the image
    // we want to clear the logo to allow a new one to be uplaoded.
    if (isOpen) {
      setFile(null)
    }
  }, [isOpen])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: 'image/*',
    onDropAccepted: handleNewAccepted,
    noClick: true,
    multiple: false,
    noKeyboard: true,
  })

  const uploadCn = cn(
    'border-2 border-dashed border-gray-300 rounded-md mt-4 py-6 px-4',
    {
      'bg-gray-200': isDragActive,
    }
  )

  const handleRemoveFile = () => {
    setFile(null)
    setZoom(0)
  }

  const renderFileStatus = () => {
    if (isPreparing) {
      return <p className="text-xs text-gray-600">Preparing...</p>
    }

    if (success) {
      return <p className="text-xs text-gray-600">Uploaded</p>
    }

    if (error) {
      return <p className="text-xs text-red-600">Error uploading file</p>
    }

    if (uploadProgress !== null) {
      return (
        <div className="flex items-center">
          <p className="text-xs mr-1.5 text-gray-600">
            Uploading ({uploadProgress}%)
          </p>
          <Line
            className="block h-2 w-[100px]"
            percent={uploadProgress}
            strokeWidth={6}
            strokeColor="#16a34a"
            trailColor="#16a34a"
            trailWidth={6}
          />
        </div>
      )
    }

    return <p className="text-xs text-gray-600">Ready to upload</p>
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title={title}
      buttonProps={{
        disabled: !fileRef.current || isUploading,
        onClick: () => handleSave(fileRef.current.getImageScaledToCanvas()),
        children: btnText,
        color: 'green',
      }}
    >
      {!file ? (
        <div {...getRootProps({ className: uploadCn })}>
          <input {...getInputProps()} />
          <div className="flex items-center justify-center text-gray-500">
            <FontAwesomeIcon icon={faUpload} size="3x" />
          </div>
          <div className="flex flex-col items-center">
            <h2 className="mt-4 text-base font-medium text-center">
              {isDragActive
                ? 'Drop your file here'
                : 'Drag and drop your file here'}
            </h2>
            <p className="my-2 text-sm uppercase text-gray-600 flex-grow-0">
              or
            </p>
            <Button size="sm" color="green" onClick={open}>
              {isDragActive ? 'Dropping files...' : 'Browse your files'}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex mt-4">
          <AvatarEditor
            className="bg-green-600 rounded-md"
            ref={(editor) => (fileRef.current = editor)}
            image={file}
            width={126}
            height={126}
            border={20}
            borderRadius={rounded ? 63 : 18}
            color={[0, 0, 0, 0.7]}
            scale={zoom}
            rotate={rotate}
            onImageReady={() => setZoom(1)}
          />
          <div className="flex-1 ml-4">
            <div>
              <label className="block text-sm font-medium mb-1">Zoom</label>
              <Slider
                min={0}
                max={2}
                step={0.01}
                onChange={(value) => setZoom(value)}
                value={zoom}
              />
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium mb-2">Rotate</label>
              <div className="space-x-2">
                <Button size="xs" onClick={() => setRotate(rotate - 90)}>
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <Button size="xs" onClick={() => setRotate(rotate + 90)}>
                  <FontAwesomeIcon icon={faRedo} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {file && (
        <div className="mt-5 mb-3">
          <h5 className="text-base font-medium mb-3">File selected</h5>
          <div
            key={file.name}
            className="flex items-center justify-between py-3 rounded-md px-4 border border-gray-200 shadow-sm"
          >
            <div className="flex items-start space-x-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-green-600 mt-1"
              />
              <div>
                <p className="font-medium text-sm mb-0.5">{file.name}</p>
                {renderFileStatus()}
              </div>
            </div>
            {!success && (
              <button
                className="rounded-md h-8 w-8 flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-600"
                onClick={handleRemoveFile}
              >
                <FontAwesomeIcon
                  size="1x"
                  className="text-gray-500"
                  icon={faTimes}
                />
              </button>
            )}
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default ImageUploadModal
