import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import cn from 'classnames'

import PaymentMethodNicknameModal from './PaymentMethodNicknameModal'
import PaymentMethodAddressModal from './PaymentMethodAddressModal'
import PaymentMethodPrimaryModal from './PaymentMethodPrimaryModal'
import PaymentMethodDeleteModal from './PaymentMethodDeleteModal'
import Button from '../Button'
import TextButton from '../TextButton'
import { getCountry } from '../../utils/address'

const PaymentMethod = (props) => {
  const {
    className,
    method,
    isExpanded,
    onSelect,
    noActions,
    selected,
    disabled,
    admin,
    dealerId,
  } = props

  const [expanded, setExpanded] = useState(isExpanded)
  const [primaryModalOpen, setPrimaryModalOpen] = useState(false)
  const [addressModalOpen, setAddressModalOpen] = useState(false)
  const [nicknameModalOpen, setNicknameModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const paymentMethodCn = cn(
    'flex-1 bg-white rounded-md shadow-sm border border-gray-200',
    {
      'ring-2 ring-green-500': selected && !disabled,
      [className]: className,
    }
  )

  const handleMakePrimary = (e) => {
    e.stopPropagation()
    setPrimaryModalOpen(true)
  }

  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect()
  }

  const renderSelect = () => {
    if (selected) {
      return (
        <span className="text-green-600 font-medium text-xs">SELECTED</span>
      )
    }

    if (disabled) {
      return <span className="text-red-600 font-medium text-xs">DISABLED</span>
    }

    if (onSelect) {
      return (
        <Button className="mt-2 sm:mt-0" size="xs" onClick={handleSelect}>
          Select method
        </Button>
      )
    }

    if (method.primary) {
      return <span className="text-teal-600 font-medium text-xs">PRIMARY</span>
    }

    return (
      <Button className="mt-2 sm:mt-0" size="xs" onClick={handleMakePrimary}>
        Make primary
      </Button>
    )
  }

  return (
    <div className={paymentMethodCn}>
      <div
        className={cn(
          'py-3 pr-10 pl-4 relative w-full rounded-tr-md rounded-tl-md sm:flex sm:items-center sm:justify-between sm:space-x-2 hover:bg-gray-50 select-none',
          {
            'rounded-bl-md rounded-br-md': !expanded,
          }
        )}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex-[2]">
          <p className="font-medium text-sm">
            {method.card.brand.toUpperCase()} ending in {method.card.last4}
          </p>
          <div className="text-xs text-gray-600 flex items-center flex-wrap">
            <p>
              Expires {method.card.expMonth}/{method.card.expYear}
            </p>
            {method.nickname && (
              <>
                <span className="mx-1">•</span>
                <p>{method.nickname}</p>
              </>
            )}
          </div>
        </div>
        <div className="flex-1">
          {renderSelect()}
          <FontAwesomeIcon
            className={cn(
              'text-gray-400 w-5 absolute top-1/2 -translate-y-1/2 right-4',
              {
                'rotate-180': expanded,
                'rotate-0': !expanded,
              }
            )}
            icon={faAngleDown}
            size="2x"
          />
        </div>
      </div>
      <div className={!expanded ? 'hidden' : 'border-t border-gray-200'}>
        <div className="py-3 px-4 space-y-4 sm:space-y-0 sm:flex sm:justify-between">
          <div className="flex-1">
            <h5 className="font-medium text-sm mb-1">Billing address</h5>
            <div className="text-xs text-gray-600">
              <p>{method.address.line1}</p>
              <p>{method.address.line2}</p>
              <p>{method.address.city}</p>
              <p>{method.address.region}</p>
              <p>{method.address.postcode}</p>
              <p>{getCountry(method.address.country)}</p>
            </div>
            {!noActions && (
              <TextButton
                size="xs"
                className="mt-2"
                onClick={() => setAddressModalOpen(true)}
              >
                Edit address
              </TextButton>
            )}
          </div>
          <div className="flex-1">
            <h5 className="font-medium text-sm mb-1">Card nickname</h5>
            <p className="text-xs text-gray-600">
              {method.nickname ? method.nickname : 'Not entered'}
            </p>
            {!noActions && (
              <TextButton
                size="xs"
                className="mt-2"
                onClick={() => setNicknameModalOpen(true)}
              >
                Edit nickname
              </TextButton>
            )}
            {!method.primary && !noActions && (
              <TextButton
                size="xs"
                theme="secondary"
                className="mt-2"
                onClick={() => setDeleteModalOpen(true)}
              >
                Delete this payment method
              </TextButton>
            )}
          </div>
        </div>
      </div>
      <PaymentMethodNicknameModal
        isOpen={nicknameModalOpen}
        setIsOpen={setNicknameModalOpen}
        method={method}
        admin={admin}
        dealerId={dealerId}
      />
      <PaymentMethodAddressModal
        isOpen={addressModalOpen}
        setIsOpen={setAddressModalOpen}
        method={method}
        admin={admin}
        dealerId={dealerId}
      />
      <PaymentMethodPrimaryModal
        isOpen={primaryModalOpen}
        setIsOpen={setPrimaryModalOpen}
        method={method}
        admin={admin}
        dealerId={dealerId}
      />
      <PaymentMethodDeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        method={method}
        admin={admin}
        dealerId={dealerId}
      />
    </div>
  )
}

export default PaymentMethod
