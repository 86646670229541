import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import history from '../../../history'
import usePrivateApi from '../../../hooks/usePrivateApi'
import useFileUpload from '../../../hooks/useFileUpload'
import Title from '../../Title'
import NewTicketBasicForm from './FormBasic'
import NewTicketFilesForm from './FormFiles'
import NewTicketFormActions from './FormActions'

const NewTicket = ({ location }) => {
  const [category, setCategory] = useState(null)
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [hasSentCreateTicket, setHasSentCreateTicket] = useState(false)

  const [{ files, allFilesUploaded }, { startUpload, addFiles, removeFile }] =
    useFileUpload('SUPPORT')

  const [{ res, success, error }, { sendRequest }] = usePrivateApi()

  useEffect(() => {
    if (success) {
      history.push({
        pathname: '/support/new/success',
        state: { ticket: res.data.ticket },
      })
    }
  }, [success, res])

  useEffect(() => {
    if (error) {
      let errCode

      if (error.response) {
        errCode = error.response.status
      } else if (error.request) {
        errCode = 100
      } else {
        errCode = 900
      }

      history.push({
        pathname: '/support/new/error',
        state: { errCode },
      })
    }
  }, [error])

  useEffect(() => {
    if (files.length > 0 && allFilesUploaded && !hasSentCreateTicket) {
      sendRequest({
        url: '/support',
        method: 'post',
        data: {
          category,
          subject,
          message,
          files: files.map((file) => file.id),
        },
      })

      setHasSentCreateTicket(true)
    }
  }, [
    files,
    category,
    message,
    subject,
    sendRequest,
    hasSentCreateTicket,
    allFilesUploaded,
  ])

  const handleOnCreate = () => {
    setIsUploading(true)

    if (files.length < 1 || allFilesUploaded) {
      sendRequest({
        url: '/support',
        method: 'post',
        data: { category, subject, message },
      })
    } else {
      startUpload()
    }
  }

  if (!location?.state?.confirmed) {
    return <Redirect to="/support/faqs" />
  }

  return (
    <>
      <Title
        title="New support ticket"
        description="Use this form to receive support from our support team."
      />
      <div className="max-w-5xl mx-auto px-4 divide-y divide-gray-200">
        <NewTicketBasicForm
          category={category}
          setCategory={setCategory}
          subject={subject}
          setSubject={setSubject}
          message={message}
          setMessage={setMessage}
          isLoading={isUploading}
        />
        <NewTicketFilesForm
          addFiles={addFiles}
          removeFile={removeFile}
          files={files}
        />
        <NewTicketFormActions
          handleOnCreate={handleOnCreate}
          isUploading={isUploading}
          category={category}
          subject={subject}
          message={message}
        />
      </div>
    </>
  )
}

export default NewTicket
