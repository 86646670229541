import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faBriefcase,
  faBan,
  faShieldCheck,
  faAt,
  faUserSlash,
} from '@fortawesome/pro-regular-svg-icons'

import UserDisableModal from './ModalDisableUser'
import UserReactivateModal from './ModalReactivateUser'
import UserCancelInviteModal from './ModalCancelInvite'
import UserResendInviteModal from './ModalResendInvite'
import UserMakeOwnerModal from './ModalMakeOwner'
import Dropdown from '../../../../../components/Dropdown'

const UsersItem = ({ dealerId, membership }) => {
  const { user, status, isOwner } = membership

  const [makeOwnerModalOpen, setMakeOwnerModalOpen] = useState(false)
  const [disableModalOpen, setDisableModalOpen] = useState(false)
  const [reactivateModalOpen, setReactivateModalOpen] = useState(false)
  const [resendInviteModalOpen, setResendInviteModalOpen] = useState(false)
  const [cancelInviteModalOpen, setCancelInviteModalOpen] = useState(false)

  const renderStatus = () => {
    if (isOwner) {
      return <span className="text-teal-600">OWNER</span>
    }

    if (status === 'PENDING') {
      return <span className="text-yellow-600">PENDING</span>
    }

    if (status === 'DISABLED') {
      return <span className="text-red-600">DISABLED</span>
    }

    return ''
  }

  const renderOptions = () => {
    const options = []

    // New owner must be active
    if (!isOwner && status === 'ACTIVE') {
      options.push({
        onClick: () => setMakeOwnerModalOpen(true),
        label: 'Make owner',
        icon: faBriefcase,
      })
    }

    // Can't disable owner account
    if (status === 'ACTIVE' && !isOwner) {
      options.push({
        onClick: () => setDisableModalOpen(true),
        label: 'Disable account',
        icon: faBan,
      })
    } else if (status === 'DISABLED') {
      options.push({
        onClick: () => setReactivateModalOpen(true),
        label: 'Re-activate account',
        icon: faShieldCheck,
      })
    } else if (status === 'PENDING') {
      options.push({
        onClick: () => setResendInviteModalOpen(true),
        label: 'Resend invitation',
        icon: faAt,
      })

      options.push({
        onClick: () => setCancelInviteModalOpen(true),
        label: 'Cancel invitation',
        icon: faUserSlash,
      })
    }

    // Don't show options button if no actions can be made
    if (options.length > 0) {
      return (
        <Dropdown
          items={options}
          renderButton={({ MenuButton }) => (
            <MenuButton className="rounded-full block p-0 ml-4 h-8 w-8 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-600 hover:bg-gray-100">
              <FontAwesomeIcon
                icon={faEllipsisV}
                size="1x"
                className="text-gray-600"
              />
            </MenuButton>
          )}
        />
      )
    }
  }

  return (
    <div className="flex-1 rounded-md shadow-sm border border-gray-200 px-4 py-3 flex items-center justify-between">
      <div>
        <p className="font-medium tracking-tight text-base">
          {user.firstName} {user.lastName}
        </p>
        <p className="text-gray-600 text-xs">{user.email}</p>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-xs uppercase font-medium">{renderStatus()}</span>
        <div>{renderOptions()}</div>
      </div>
      <UserDisableModal
        dealerId={dealerId}
        isOpen={disableModalOpen}
        setIsOpen={() => setDisableModalOpen(false)}
        membership={membership}
      />
      <UserReactivateModal
        dealerId={dealerId}
        isOpen={reactivateModalOpen}
        setIsOpen={() => setReactivateModalOpen(false)}
        membership={membership}
      />
      <UserCancelInviteModal
        dealerId={dealerId}
        isOpen={cancelInviteModalOpen}
        setIsOpen={() => setCancelInviteModalOpen(false)}
        membership={membership}
      />
      <UserResendInviteModal
        dealerId={dealerId}
        isOpen={resendInviteModalOpen}
        setIsOpen={() => setResendInviteModalOpen(false)}
        membership={membership}
      />
      <UserMakeOwnerModal
        dealerId={dealerId}
        isOpen={makeOwnerModalOpen}
        setIsOpen={() => setMakeOwnerModalOpen(false)}
        membership={membership}
      />
    </div>
  )
}

export default UsersItem
