import React, { useState } from 'react'

import InputGroup from '../../InputGroup'
import Textarea from '../../Textarea'
import ExternalLink from '../../ExternalLink'
import Button from '../../Button'
import AutoPopUpModal from '../../AutoPopUp'

const AutoPopUp = (props) => {
  const { isSaving, onChange, autoPopUp } = props

  const [previewOpen, setPreviewOpen] = useState(false)

  const disabled =
    !autoPopUp.title ||
    !autoPopUp.content ||
    !autoPopUp.buttonText ||
    !autoPopUp.buttonHref

  const renderMissingFields = () => {
    if (!disabled) {
      return null
    }

    const renderMessage = () => {
      if (!autoPopUp.title) {
        return 'Enter a heading'
      }

      if (!autoPopUp.content) {
        return 'Enter a body'
      }

      if (!autoPopUp.buttonText) {
        return 'Enter button text'
      }

      if (!autoPopUp.buttonHref) {
        return 'Enter a button link'
      }
    }

    return (
      <div className="mt-3">
        <p className="text-gray-600 text-sm">
          To enable preview you need to:{' '}
          <span className="underline">{renderMessage()}</span>
        </p>
      </div>
    )
  }

  return (
    <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Automatic pop-up
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>Present the dealer with a pop-up each time they login.</p>
        </div>
      </div>
      <div className="flex-1 space-y-4">
        <InputGroup
          required
          className="max-w-xs"
          type="select"
          label="Enable automatic pop-up?"
          id="enableAutoPopUp"
          disabled={isSaving}
          onChange={(e) => onChange({ active: e.target.value === 'true' })}
          value={`${autoPopUp.active}`}
          renderOptions={() => (
            <>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </>
          )}
        />
        <InputGroup
          required
          label="Heading"
          id="autoPopUpTitle"
          placeholder="KESS3 now in stock!"
          disabled={isSaving}
          onChange={(e) => onChange({ title: e.target.value })}
          value={autoPopUp.title}
        />
        <Textarea
          required
          label="Body"
          id="autoPopUpContent"
          placeholder="Enter text to appear in pop-up..."
          onChange={(e) => onChange({ content: e.target.value })}
          value={autoPopUp.content}
          disabled={isSaving}
        />
        <InputGroup
          required
          placeholder="Order now"
          className="max-w-[150px]"
          label="Button text"
          id="autoPopUpButtonText"
          disabled={isSaving}
          onChange={(e) => onChange({ buttonText: e.target.value })}
          value={autoPopUp.buttonText}
        />
        <div>
          <InputGroup
            required
            placeholder="mailto:files@dktuning.co.uk?subject=Order%20KESS3"
            label="Button link"
            id="autoPopUpButtonText"
            disabled={isSaving}
            onChange={(e) => onChange({ buttonHref: e.target.value })}
            value={autoPopUp.buttonHref}
          />
          <p className="text-xs text-gray-600 mt-2">
            This can be a link to a web page (https://), or a link to a
            pre-populated email (mailto:). If you wish to create a prepopulated
            email, use this tool and copy the resulting link:{' '}
            <ExternalLink
              className="text-green-600 hover:underline whitespace-nowrap"
              to="https://mailtolink.me"
            >
              mailtolink.me
            </ExternalLink>
            .
          </p>
          <p className="text-xs text-gray-600 mt-2 underline">
            Note that the link must start with either <strong>https://</strong>{' '}
            or <strong>mailto:</strong> in order to work properly.
          </p>
        </div>
        <div>
          <Button
            size="sm"
            onClick={() => setPreviewOpen(true)}
            disabled={disabled}
          >
            Preview pop-up
          </Button>
          {renderMissingFields()}
        </div>
      </div>
      <AutoPopUpModal
        isOpen={previewOpen}
        setIsOpen={() => setPreviewOpen(false)}
        autoPopUp={autoPopUp}
      />
    </div>
  )
}

export default AutoPopUp
