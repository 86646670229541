import React, { useEffect } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import Title from '../../Title'
import history from '../../../history'

const NewTicketSuccess = ({ location }) => {
  const ticket = _.get(location, 'state.ticket', null)

  useEffect(() => {
    if (!ticket) {
      history.push('/dashboard')
    }
  }, [ticket])

  return (
    <>
      <Title title="Support ticket created" />
      <div className="max-w-5xl mx-auto px-4 py-1">
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Your support ticket number is {ticket.ticketNumber}
        </h3>
        <p className="text-sm text-gray-600 mt-2 mb-6">
          We aim to respond to all support tickets within 1 hour.
        </p>
        <div className="mt-6 space-x-4">
          <Button
            as={Link}
            color="green"
            to={{
              pathname: `/support/view/${ticket.id}`,
              state: { ticket },
            }}
          >
            View ticket
          </Button>
          <Button color="white" as={Link} to="/support/list/open">
            View open tickets
          </Button>
        </div>
      </div>
    </>
  )
}

export default NewTicketSuccess
