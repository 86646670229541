import React from 'react'

import CheckBox from '../../../CheckBox'

const NewUploadAddonPriority = (props) => {
  const { isLoading, priority, setPriority } = props

  return (
    <CheckBox
      checked={priority}
      disabled={isLoading}
      description="Mark this upload as a priority file and receive a faster turnaround."
      onChange={() => setPriority(!priority)}
      label={
        <>
          Priority file (0.5 credit){' '}
          <span className="bg-pink-100 py-0.5 px-1.5 align-baseline rounded-md text-pink-700 ml-1 text-xs font-semibold tracking-tight">
            New
          </span>
        </>
      }
    />
  )
}

export default NewUploadAddonPriority
