import React, { useState, useEffect, useContext } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import AuthContext from '../../context/AuthContext'
import useObjState from '../../hooks/useObjState'
import usePrivateApi from '../../hooks/usePrivateApi'
import Dialog from '../Dialog'
import InputGroup from '../InputGroup'
import { getCountryOptions } from '../../utils/address'
import Switch from '../Switch'
import Button from '../Button'

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      backgroundColor: '#ffffff',
      color: '#000000',
      lineHeight: '18px',
      fontFamily:
        '"Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#A0AEC0',
      },
      ':-webkit-autofill': {
        color: '#A0AEC0',
      },
    },
  },
}

const NewPaymentMethodModal = ({ isOpen, setIsOpen, isPrimary }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [sameAddress, setSameAddress] = useState(true)
  const [clientSecret, setClientSecret] = useState(null)
  const [showFetchErr, setShowFetchErr] = useState(null)
  const [stripeErr, setStripeErr] = useState(null)
  const [stripeSuccess, setStripeSuccess] = useState(null)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [billingAddress, setBillingAddress] = useObjState({
    line1: '',
    line2: '',
    city: '',
    region: '',
    postcode: '',
    country: 'GB',
  })

  const [auth] = useContext(AuthContext)
  const { dealer, user } = auth.membership

  const [
    { isLoading, res, success, error },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  useEffect(() => {
    if (isOpen) {
      setSameAddress(true)
      setClientSecret(null)
      setShowFetchErr(null)
      setStripeErr(null)
      setStripeSuccess(null)
      setBillingAddress({
        line1: '',
        line2: '',
        city: '',
        region: '',
        postcode: '',
        country: 'GB',
      })
      sendRequest({ url: '/billing/payment-methods/setup' })
    }
  }, [isOpen, sendRequest, setBillingAddress])

  useEffect(() => {
    if (success && res.data.clientSecret) {
      resetSuccess()
      setClientSecret(res.data.clientSecret)
    }
  }, [clientSecret, res, resetSuccess, success])

  useEffect(() => {
    if (error && !clientSecret) {
      resetError()
      setShowFetchErr(true)
    }
  }, [clientSecret, error, resetError])

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }

    const address = sameAddress ? dealer.address : billingAddress

    let result

    try {
      setStripeLoading(true)

      result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            address: {
              line1: address.line1,
              line2: address.line2,
              city: address.city,
              state: address.region,
              postal_code: address.postcode,
              country: address.country,
            },
          },
          metadata: {
            primary: isPrimary,
          },
        },
      })

      setStripeLoading(false)
    } catch (err) {
      setStripeErr('We encountered an error - please try again later.')
    }

    if (result.error) {
      setStripeErr(result.error.message)
    } else {
      setStripeSuccess(true)
    }
  }

  if (showFetchErr) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          You are unable to add a payment method at this time. Please try again
          later.
        </p>
      </Dialog>
    )
  }

  if (stripeErr) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Could not save payment method"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">{stripeErr}</p>
        <Button className="mt-4" onClick={setIsOpen}>
          Close and try again
        </Button>
      </Dialog>
    )
  }

  if (stripeSuccess) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Payment method saved"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Your payment method has been added to your account and is available
          for immediate use.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Add payment method"
      buttonProps={{
        disabled: stripeLoading,
        onClick: handleSubmit,
        children: stripeLoading ? 'Saving...' : 'Add payment method',
        color: 'green',
      }}
    >
      <div className="space-y-4 mt-2">
        <div>
          <label className="block text-sm font-medium leading-5 text-black">
            Card details
          </label>
          <div>
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              disabled={isLoading || stripeLoading}
              onReady={(e) => e.focus()}
            />
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-gray-600 text-sm">
            Billing address same as business address?
          </span>
          <Switch
            checked={sameAddress}
            onChange={() => setSameAddress(!sameAddress)}
            disabled={isLoading || stripeLoading}
            id="addressToggle"
            small
          />
        </div>
        {!sameAddress && (
          <>
            <p className="mt-2 text-sm font-medium">Billing address</p>
            <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
              <InputGroup
                required
                className="flex-1"
                label="Address line one"
                id="addressLine1"
                disabled={isLoading || stripeLoading}
                onChange={(e) => setBillingAddress({ line1: e.target.value })}
                value={billingAddress.line1}
              />
              <InputGroup
                className="flex-1"
                label="Address line two"
                id="addressLine2"
                disabled={isLoading || stripeLoading}
                onChange={(e) => setBillingAddress({ line2: e.target.value })}
                value={billingAddress.line2}
              />
            </div>
            <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
              <InputGroup
                className="flex-1"
                label="City/town"
                id="city"
                disabled={isLoading || stripeLoading}
                onChange={(e) => setBillingAddress({ city: e.target.value })}
                value={billingAddress.city}
              />
              <InputGroup
                className="flex-1"
                label="Region"
                id="region"
                disabled={isLoading || stripeLoading}
                onChange={(e) => setBillingAddress({ region: e.target.value })}
                value={billingAddress.region}
              />
            </div>
            <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
              <InputGroup
                required
                className="flex-1"
                label="Postcode"
                id="postcode"
                disabled={isLoading || stripeLoading}
                onChange={(e) =>
                  setBillingAddress({
                    postcode: e.target.value.toUpperCase(),
                  })
                }
                value={billingAddress.postcode}
              />
              <InputGroup
                required
                className="flex-1"
                type="select"
                disabled={isLoading || stripeLoading}
                label="Country"
                id="country"
                onChange={(e) => setBillingAddress({ country: e.target.value })}
                renderOptions={getCountryOptions}
                value={billingAddress.country || 'GB'}
              />
            </div>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default NewPaymentMethodModal
