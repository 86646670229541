import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import { format, isFuture } from 'date-fns'

import ToolEditModal from './ModalEditTool'
import ToolDeleteModal from './ModalDeleteTool'
import EnableAutoRenewModal from './ModalEnableAutoRenew'
import DisableAutoRenewModal from './ModalDisableAutoRenew'
import Switch from '../../../../../components/Switch'
import Dropdown from '../../../../../components/Dropdown'

const ToolItem = ({ tool, hasPaymentMethod, dealer }) => {
  const [autoRenewOn, setAutoRenewOn] = useState(tool.autoRenewOn)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [enableAutoRenewModalOpen, setEnableAutoRenewModalOpen] =
    useState(false)
  const [disableAutoRenewModalOpen, setDisableAutoRenewModalOpen] =
    useState(false)

  const nextExpiry = tool.nextExpiry ? new Date(tool.nextExpiry) : null

  const renderStatus = () => {
    if (tool.status === 'ACTIVE') {
      return <span className="text-teal-600">ACTIVE</span>
    }

    if (tool.status === 'PAYMENT_ATTEMPTED') {
      return <span className="text-yellow-600">PAYMENT ATTEMPTED</span>
    }

    if (tool.status === 'PAYMENT_ACTION_NEEDED') {
      return <span className="text-red-600">ACTION NEEDED</span>
    }

    if (tool.status === 'PAYMENT_FAILED') {
      return <span className="text-red-600">PAYMENT FAILED</span>
    }

    if (tool.status === 'EXPIRED') {
      return <span className="text-red-600">EXPIRED</span>
    }

    return ''
  }

  return (
    <div className="flex-1 rounded-md shadow-sm border border-gray-200 px-4 py-3 flex items-center justify-between">
      <div>
        <p className="font-medium tracking-tight text-base">
          {tool.name} ({tool.serialNumber})
        </p>
        <p className="text-gray-600 text-xs">
          {nextExpiry ? (
            <>
              {isFuture(nextExpiry) ? 'Expires' : 'Expired on'}{' '}
              {format(new Date(tool.nextExpiry), 'dd MMM yyyy')}
            </>
          ) : (
            'Never expires'
          )}
        </p>
        {tool.key !== 'AUTOTUNER' && (
          <>
            <div className="mt-2">
              <label
                className="flex items-center space-x-2"
                htmlFor={`autoRenew-${tool.id}`}
              >
                <Switch
                  checked={autoRenewOn}
                  small
                  onChange={(checked) => {
                    setAutoRenewOn(checked)
                    checked
                      ? setEnableAutoRenewModalOpen(true)
                      : setDisableAutoRenewModalOpen(true)
                  }}
                  id={`autoRenew-${tool.id}`}
                />
                <span className="text-sm text-gray-600">
                  Auto-renew {autoRenewOn ? 'on' : 'off'}
                </span>
              </label>
            </div>
          </>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-xs uppercase font-medium">{renderStatus()}</span>
        <div className="tool-list-item-options-max-w-5xl mx-auto px-4">
          <Dropdown
            items={[
              [
                {
                  onClick: () => setEditModalOpen(true),
                  label: 'Edit tool details',
                  icon: faPencilAlt,
                },
                {
                  onClick: () => setDeleteModalOpen(true),
                  label: 'Remove tool',
                  icon: faTrashAlt,
                },
              ],
            ]}
            renderButton={({ MenuButton }) => (
              <MenuButton className="rounded-full block p-0 ml-4 h-8 w-8 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-600 hover:bg-gray-100">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  size="1x"
                  className="text-gray-600"
                />
              </MenuButton>
            )}
          />
        </div>
      </div>
      <EnableAutoRenewModal
        hasPaymentMethod={hasPaymentMethod}
        isOpen={enableAutoRenewModalOpen}
        setIsOpen={() => setEnableAutoRenewModalOpen(false)}
        tool={tool}
        dealer={dealer}
      />
      <DisableAutoRenewModal
        isOpen={disableAutoRenewModalOpen}
        setIsOpen={() => setDisableAutoRenewModalOpen(false)}
        tool={tool}
        dealer={dealer}
      />
      <ToolEditModal
        isOpen={editModalOpen}
        setIsOpen={() => setEditModalOpen(false)}
        tool={tool}
        dealer={dealer}
      />
      <ToolDeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={() => setDeleteModalOpen(false)}
        tool={tool}
        dealer={dealer}
      />
    </div>
  )
}

export default ToolItem
