import React from 'react'

import Dialog from './Dialog'

const AutoPopUp = (props) => {
  const { isOpen, setIsOpen, autoPopUp } = props

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title={autoPopUp.title}
      buttonProps={{
        children: autoPopUp.buttonText,
        as: 'a',
        color: 'green',
        target: '_blank',
        rel: 'noopener noreferrer',
        href: autoPopUp.buttonHref,
      }}
    >
      <p className="mt-2 text-gray-600 text-sm whitespace-pre-wrap">
        {autoPopUp.content}
      </p>
    </Dialog>
  )
}

export default AutoPopUp
