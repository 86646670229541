import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const SubdealerResendInviteModal = ({ isOpen, setIsOpen, subdealer }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/subdealers/invite/${subdealer.id}`,
      method: 'post',
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not resend invite. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Subdealer invite sent"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          We have resent the invite for <strong>{subdealer.name}</strong>
        </p>
        <p className="text-gray-600 text-sm mt-2">
          This invitation will expire in 24 hours, in which case you will need
          to resend the invitation.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Resend invite"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Sending...' : 'Send invite',
        color: 'green',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        Are you sure you wish to send <strong>{subdealer.name}</strong> a new
        invite?
      </p>
      <p className="text-gray-600 text-sm mt-2">
        The old invitation email will no longer work, so make sure{' '}
        <strong>{subdealer.name}</strong> uses the new email we send them.
      </p>
    </Dialog>
  )
}

export default SubdealerResendInviteModal
