import React, { useState, useCallback } from 'react'

import Title from '../Title'
import VrmLookup from './VrmLookup'
import ManualLookup from './ManualLookup'
import LookupResults from './LookupResults'
import RadioGroup from '../RadioGroup'

const VehicleData = () => {
  const [lookupType, setLookupType] = useState('VRM')
  const [hideDetails, setHideDetails] = useState(false)
  const [vehicle, setVehicle] = useState(null)
  const [otherVehicles, setOtherVehicles] = useState(null)
  const [vrm, setVrm] = useState(null)

  const handleVehicleChange = useCallback(({ vehicle, otherVehicles, vrm }) => {
    setVehicle(vehicle)
    setOtherVehicles(otherVehicles)
    setHideDetails(true)
    setVrm(vrm || null)
  }, [])

  const handleOptionClick = useCallback(() => {
    setHideDetails(false)
  }, [])

  const Container = useCallback(
    ({ children }) => (
      <div className="space-y-3 md:space-y-0 md:flex md:items-start md:space-x-3">
        {children}
      </div>
    ),
    []
  )

  return (
    <>
      <Title
        title="Vehicle data"
        description="Use this form to find data on a vehicle, including before and after remap performance."
      />
      <div className="max-w-5xl mx-auto px-4">
        <RadioGroup
          selected={lookupType}
          setSelected={setLookupType}
          groupLabel="Lookup type"
          Container={Container}
          items={[
            {
              label: 'VRM lookup',
              description:
                'Search for a vehicle by registration number (UK only)',
              value: 'VRM',
              onClick: handleOptionClick,
              renderContent: ({ checked }) => (
                <VrmLookup
                  checked={checked}
                  onVehicleChange={handleVehicleChange}
                  hideDetails={hideDetails}
                />
              ),
            },
            {
              label: 'Manual lookup',
              description:
                'Search for a vehicle by manually selecting the correct data',
              value: 'MANUAL',
              onClick: handleOptionClick,
              renderContent: ({ checked }) => (
                <ManualLookup
                  checked={checked}
                  onVehicleChange={handleVehicleChange}
                  hideDetails={hideDetails}
                />
              ),
            },
          ]}
        />
        {vehicle && (
          <div>
            <h3 className="text-xl font-semibold tracking-tight mt-8 mb-8">
              Lookup results
            </h3>
            <LookupResults
              vehicle={vehicle}
              otherVehicles={otherVehicles}
              vrm={vrm}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default VehicleData
