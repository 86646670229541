import React, { useState } from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import { toolLookup } from '../../../utils/dealers'
import { objectToOptions } from '../../../utils/objects'
import InputGroup from '../../../components/InputGroup'

const NewToolModal = ({ isOpen, setIsOpen }) => {
  const [toolKey, setToolKey] = useState('KESS_V2')
  const [serialNumber, setSerialNumber] = useState('')
  const [nextExpiry, setNextExpiry] = useState(new Date())

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: '/settings/tools',
      method: 'post',
      data: {
        key: toolKey,
        serialNumber,
        nextExpiry: toolKey !== 'AUTOTUNER' ? nextExpiry : null,
      },
    })
  }

  const renderError = () => {
    if (error) {
      return (
        <p className="text-red-600 text-sm mt-2">
          Could not save tool. Please try again later.
        </p>
      )
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        {renderError()}
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Tool added to your account"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          You have successfully added this tool to your account.
        </p>
        <p className="text-gray-600 text-sm mt-2">
          You can now enable auto-renew on this tool by clicking on the
          auto-renew toggle in your tool list.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Add tool"
      buttonProps={{
        disabled: isLoading || !toolKey || !serialNumber || !nextExpiry,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save tool',
        color: 'green',
      }}
    >
      <div className="space-y-4 mt-2">
        <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
          <InputGroup
            required
            className="flex-1"
            type="select"
            label="Tool type"
            id="toolKey"
            onChange={(e) => setToolKey(e.target.value)}
            value={toolKey}
            renderOptions={() => objectToOptions(toolLookup)}
          />
          <InputGroup
            required
            className="flex-1"
            label="Serial number"
            id="serialNumber"
            disabled={isLoading}
            onChange={(e) => setSerialNumber(e.target.value)}
            value={serialNumber}
          />
        </div>
        {toolKey !== 'AUTOTUNER' && (
          <InputGroup
            required
            inModal
            className="max-w-xs"
            type="date"
            label="Next subscription expiry date"
            id="nextExpiry"
            help="Must be a date in the future"
            disabled={isLoading}
            onChange={(date) => setNextExpiry(date)}
            value={nextExpiry}
          />
        )}
      </div>
    </Dialog>
  )
}

export default NewToolModal
