import React from 'react'
import { isFuture } from 'date-fns'

import usePrivateApi from '../../../../../hooks/usePrivateApi'
import Button from '../../../../../components/Button'
import Dialog from '../../../../../components/Dialog'

const EnableAutoRenewModal = ({ isOpen, tool, hasPaymentMethod, dealer }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/dealers/${dealer.id}/tools/${tool.id}/subscription`,
      method: 'post',
    })
  }

  if (!isFuture(new Date(tool.nextExpiry))) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Cannot enable auto-renew"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          We could not enable auto-renew because the expiry date has already
          past.
        </p>
        <p className="mt-2 text-gray-600 text-sm">
          Once the subscription is up-to-date auto-renew can be enabled.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (!hasPaymentMethod) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Cannot enable auto-renew"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          We could not enable auto-renew because the dealers does not have a
          payment method in their account.
        </p>
        <p className="mt-2 text-gray-600 text-sm">
          Please ask the dealer to add a payment method by going to the
          "billing" section of their account settings.
        </p>
        <p className="mt-2 text-gray-600 text-sm">
          Once the dealer has added a payment method please try again.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not enable auto-renew. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Auto-renew is enabled"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          You have successfully enabled auto-renew for this {tool.name}. We'll
          send the dealer an email reminder 7 days before payment is collected.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => window.location.reload()}
      title="Enable auto-renew"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Enabling...' : 'Enable',
        color: 'green',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        When enabling auto-renew the following will happen:
      </p>
      <h5 className="text-sm font-medium mt-4">10 days before expiry</h5>
      <p className="mt-1 text-gray-600 text-sm">
        We'll email the dealer a reminder that we will take payment in the next
        7 days.
      </p>
      <h5 className="text-sm font-medium mt-4">3 days before expiry</h5>
      <p className="mt-1 text-gray-600 text-sm">
        We attempt to take payment for the tool subscription using the dealer's
        primary payment method.
      </p>
      <h5 className="text-sm font-medium mt-4">1 day before expiry</h5>
      <p className="mt-1 text-gray-600 text-sm">
        We renew the tool subscription for another year.
      </p>
      <p className="mt-4 text-gray-600 text-sm">
        You can turn off auto-renew at any point.
      </p>
    </Dialog>
  )
}

export default EnableAutoRenewModal
