import React from 'react'
import cn from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

const SelectMenu = (props) => {
  const { buttonText, selected, setSelected, items, multiple } = props

  return (
    <Listbox value={selected} onChange={setSelected} multiple={multiple}>
      <div className="relative">
        <Listbox.Button className="border border-gray-300 inline-flex justify-center items-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 active:text-gray-800 active:bg-gray-50">
          {buttonText}
          <FontAwesomeIcon
            icon={faChevronDown}
            className="ml-2 -mr-1 h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 right-0 origin-top-right z-10 max-h-60 w-48 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {items.map((item, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  cn('relative cursor-default select-none py-2 pl-10 pr-4', {
                    'bg-green-100 text-green-900': active,
                    'text-gray-900': !active,
                  })
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={cn('block truncate', {
                        'font-medium': selected,
                      })}
                    >
                      {item.label}
                    </span>
                    {selected && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="w-5 h-5"
                          size="1x"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default SelectMenu
