import React from 'react'

const Service = ({ label, value }) => {
  return (
    <div className="flex pb-1 mb-2 justify-between items-center text-sm border-b border-gray-200">
      <span>{label}:</span>
      <span className="font-semibold">{value ? 'Yes' : 'No'}</span>
    </div>
  )
}

const AvailableServices = ({ vehicle }) => {
  return (
    <div>
      <h4 className="text-base font-medium">Available services</h4>
      <div className="mt-4">
        <Service label="DPF off" value={vehicle.dpfOff} />
        <Service label="EGR off" value={vehicle.egrOff} />
        <Service label="O2 off" value={vehicle.o2Off} />
        <Service label="Launch patch" value={vehicle.launchPatch} />
        <Service label="Hard cut limiter" value={vehicle.hardCutLimiter} />
        <Service label="Adblue" value={vehicle.adblue} />
        <Service label="MAF removal" value={vehicle.mafRemoval} />
        <Service
          label="Swirl flaps removal"
          value={vehicle.swirlFlapsRemoval}
        />
        <Service label="Start/Stop disable" value={vehicle.startStopDisable} />
        <Service label="TVA" value={vehicle.tva} />
      </div>
    </div>
  )
}

export default AvailableServices
