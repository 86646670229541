import React from 'react'

import Button from '../components/Button'
import TextButton from '../components/TextButton'
import InputGroup from '../components/InputGroup'
import { getCountryOptions } from '../utils/address'

const SignupDealer = (props) => {
  const {
    isLoading,
    businessName,
    setBusinessName,
    phone,
    setPhone,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    addressCity,
    setAddressCity,
    addressRegion,
    setAddressRegion,
    addressPostcode,
    setAddressPostcode,
    addressCountry,
    setAddressCountry,
    onPrevious,
    onSubmit,
    generalError,
  } = props

  const btnDisabled =
    businessName === '' ||
    addressLine1 === '' ||
    addressPostcode === '' ||
    phone === '' ||
    isLoading

  return (
    <form onSubmit={onSubmit}>
      <h2 className="text-lg md:text-xl font-medium tracking-tight">
        Business name and address
      </h2>
      {generalError && (
        <p className="text-red-600 text-sm mt-3">{generalError}</p>
      )}
      <div className="space-y-4 mt-4">
        <InputGroup
          autoFocus
          required
          disabled={isLoading}
          label="Business name"
          id="businessName"
          onChange={(e) => setBusinessName(e.target.value)}
          value={businessName}
        />
        <InputGroup
          required
          disabled={isLoading}
          label="Address line one"
          id="addressLineOne"
          onChange={(e) => setAddressLine1(e.target.value)}
          value={addressLine1}
        />
        <div className="space-y-4 mt-4 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            className="flex-1"
            disabled={isLoading}
            label="Address line two"
            id="addressLineTwo"
            onChange={(e) => setAddressLine2(e.target.value)}
            value={addressLine2}
          />
          <InputGroup
            className="flex-1"
            disabled={isLoading}
            label="City/town"
            id="city"
            onChange={(e) => setAddressCity(e.target.value)}
            value={addressCity}
          />
        </div>
        <div className="space-y-4 mt-4 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            className="flex-1"
            disabled={isLoading}
            label="Region"
            id="region"
            onChange={(e) => setAddressRegion(e.target.value)}
            value={addressRegion}
          />
          <InputGroup
            required
            className="flex-1"
            disabled={isLoading}
            label="Postcode"
            id="postcode"
            onChange={(e) => setAddressPostcode(e.target.value.toUpperCase())}
            value={addressPostcode}
          />
        </div>
        <div className="space-y-4 mt-4 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            required
            className="flex-1"
            type="select"
            disabled={isLoading}
            label="Country"
            id="country"
            onChange={(e) => setAddressCountry(e.target.value)}
            renderOptions={getCountryOptions}
            value={addressCountry}
          />
          <InputGroup
            required
            className="flex-1"
            disabled={isLoading}
            label="Phone number"
            id="phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
        </div>
      </div>
      <Button
        block
        color="green"
        className="mt-6"
        size="xl"
        disabled={btnDisabled}
        type="submit"
      >
        {isLoading ? 'Creating...' : 'Create your account'}
      </Button>
      <TextButton
        theme="secondary"
        className="mt-8 sm:mt-12 mx-auto"
        size="xs"
        onClick={onPrevious}
      >
        ⟵ Change name and login details
      </TextButton>
    </form>
  )
}

export default SignupDealer
