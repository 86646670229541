import React, { useEffect } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

const BoxNav = ({ current, onChange, links }) => {
  useEffect(() => {
    if (onChange) {
      links.forEach((link) => {
        if (current.includes(link.to)) {
          onChange(link.key)
        }
      })
    }
  }, [links, current, onChange])

  return (
    <nav className="flex overflow-x-auto space-x-3 px-4">
      {links.map((link) => (
        <Link
          className={cn(
            'relative px-4 pt-6 pb-4 font-medium whitespace-nowrap group text-sm',
            {
              'text-gray-500': !current.includes(link.to),
              'text-gray-900': current.includes(link.to),
            }
          )}
          key={link.to}
          to={link.to}
        >
          {link.label}
          <span
            className={cn(
              'absolute left-0 right-0 bottom-0 h-[3px] rounded-tr-md rounded-tl-md',
              {
                'bg-transparent group-hover:bg-gray-300': !current.includes(
                  link.to
                ),
                'bg-green-600': current.includes(link.to),
              }
            )}
          />
        </Link>
      ))}
    </nav>
  )
}

export default BoxNav
