import React from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import Button from './Button'

const defaultButtonProps = {
  color: 'green',
  size: 'md',
  children: 'Save',
  type: 'submit',
}

const Modal = (props) => {
  const { isOpen, setIsOpen, title, children, buttonProps = {} } = props

  let mergedButtonProps =
    buttonProps !== null ? { ...defaultButtonProps, ...buttonProps } : null

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => (setIsOpen ? setIsOpen(false) : {})}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold tracking-tight leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  {setIsOpen && (
                    <button
                      className="rounded-lg group w-8 h-8 hover:bg-gray-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                      type="button"
                      onClick={() => setIsOpen(false)}
                      tabIndex="-1"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="1x"
                        className="text-gray-500 group-hover:text-gray-600"
                      />
                    </button>
                  )}
                </div>
                <div>
                  {children}
                  {mergedButtonProps && (
                    <div className="flex justify-end mt-6">
                      <Button {...mergedButtonProps}></Button>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
