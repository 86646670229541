import React, { useEffect } from 'react'

import NewUploadAddonWarranty from './Warranty'
import NewUploadAddonFilter from './Filter'
import NewUploadAddonPriority from './Priority'

const NewUploadAddonForm = (props) => {
  const {
    isLoading,
    dealerAddress,
    availableAddons,
    vehicle,
    mileage,
    warrantyData,
    setWarrantyData,
    filterData,
    setFilterData,
    vehicleType,
    priority,
    setPriority,
  } = props

  // Update our filter addon with the number of filters needed
  // for the vehicle selected
  useEffect(() => {
    if (vehicle) {
      if (vehicle.filtersNeeded === 1) {
        setFilterData({ type: 'FILTERS_1' })
      } else if (vehicle.filtersNeeded === 2) {
        setFilterData({ type: 'FILTERS_2' })
      }
    }
  }, [vehicle, setFilterData])

  const copyWarrantyData = () => {
    setFilterData({
      customerName: warrantyData.customerName,
      customerEmail: warrantyData.customerEmail,
    })
  }

  const showAddonTable = availableAddons.reduce((obj, addon) => {
    obj[addon.type] = true
    return obj
  }, {})

  const showWarranty =
    vehicleType === 'CAR' &&
    (showAddonTable['WARRANTY_12'] || showAddonTable['WARRANTY_24']) &&
    mileage < 100000

  const showFilter =
    vehicleType === 'CAR' &&
    ((showAddonTable['FILTERS_1'] && filterData.type === 'FILTERS_1') ||
      (showAddonTable['FILTERS_2'] && filterData.type === 'FILTERS_2'))

  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 py-8">
      <div className="flex-[2]">
        <h3 className="text-xl font-semibold tracking-tight">Add-ons</h3>
        <div className="space-y-2 mt-1 text-gray-700 text-sm">
          <p>
            Add-ons are extra products and services you can purchase to re-sell
            to your customer.
          </p>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-3">
        {vehicleType !== 'CAR' || (vehicle && mileage) ? (
          <>
            <NewUploadAddonPriority
              priority={priority}
              setPriority={setPriority}
              isLoading={isLoading}
            />
            {showWarranty && (
              <NewUploadAddonWarranty
                isLoading={isLoading}
                warrantyData={warrantyData}
                setWarrantyData={setWarrantyData}
              />
            )}
            {showFilter && (
              <NewUploadAddonFilter
                isLoading={isLoading}
                dealerAddress={dealerAddress}
                copyWarrantyData={copyWarrantyData}
                showCopyWarrantyData={
                  warrantyData.customerName || warrantyData.customerEmail
                }
                vehicle={vehicle}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            )}
            {(showWarranty || showFilter) && (
              <p className="text-gray-600 text-xs">
                UK VAT will be charged if applicable. Review the checkout
                summary to confirm.
              </p>
            )}
          </>
        ) : (
          <div className="text-sm text-center pt-3 pb-4">
            <span>Choose vehicle and enter mileage to view add-ons</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default NewUploadAddonForm
