import React from 'react'
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import cn from 'classnames'

const RadioGroup = (props) => {
  const {
    selected,
    setSelected,
    groupLabel,
    items,
    disabled,
    Container = React.Fragment,
  } = props

  return (
    <HeadlessRadioGroup
      value={selected}
      onChange={setSelected}
      disabled={disabled}
    >
      {groupLabel && (
        <HeadlessRadioGroup.Label className="sr-only">
          {groupLabel}
        </HeadlessRadioGroup.Label>
      )}
      <Container>
        {items.map((item) => (
          <HeadlessRadioGroup.Option
            key={item.label}
            value={item.value}
            onClick={item.onClick || undefined}
            disabled={item.disabled}
            className={({ checked }) =>
              cn(
                'relative border p-4 flex flex-1 items-start cursor-pointer rounded-md md:pl-4 md:pr-6 focus:outline-none',
                {
                  'bg-green-50 border-transparent z-10 ring-2 ring-green-500':
                    checked,
                  'bg-white border-gray-300 hover:bg-gray-50': !checked,
                }
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center text-sm mt-1">
                  <span
                    className={cn(
                      'h-4 w-4 rounded-full border flex items-center justify-center',
                      {
                        'bg-green-600 border-transparent': checked,
                        'bg-white border-gray-300': !checked,
                        'ring-2 ring-offset-2 ring-green-500': active,
                      }
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                </span>
                <div className="ml-3 flex-1">
                  <HeadlessRadioGroup.Label
                    as="p"
                    className="font-medium tracking-tight text-gray-900"
                  >
                    {item.label}
                  </HeadlessRadioGroup.Label>
                  <HeadlessRadioGroup.Description
                    as="p"
                    className={cn('mt-1 text-sm', {
                      'text-gray-900': checked,
                      'text-gray-600': !checked,
                    })}
                  >
                    {item.description}
                  </HeadlessRadioGroup.Description>
                  {item.renderContent &&
                    item.renderContent({ active, checked })}
                </div>
              </>
            )}
          </HeadlessRadioGroup.Option>
        ))}
      </Container>
    </HeadlessRadioGroup>
  )
}

export default RadioGroup
