import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonGroup = (props) => {
  const { className, items, size = 'md' } = props

  const spanCn = cn(
    className,
    'relative z-0 inline-flex shadow-sm rounded-md overflow-visible'
  )

  const buttonSizeCn = {
    xs: ['py-1.5', 'text-xs', 'leading-4'],
    sm: ['py-2', 'text-sm', 'leading-4'],
    md: ['py-2', 'text-sm', 'leading-5'],
    lg: ['py-2', 'text-base', 'leading-6'],
    xl: ['py-3', 'text-base', 'leading-6'],
  }

  const buttonBaseCn = [
    'relative',
    'inline-flex',
    'items-center',
    'border',
    'border-gray-300',
    'text-gray-900',
    'bg-white',
    'font-medium',
    'focus:z-10',
    'focus:outline-none',
  ]

  const buttonActiveCn = [
    'hover:text-gray-700',
    'hover:bg-gray-50',
    'focus:ring-1',
    'focus:ring-green-500',
    'focus:border-green-500',
    'active:text-gray-800',
    'active:bg-gray-50',
  ]

  const iconSizeCn = {
    xs: ['h-4', 'w-4'],
    sm: ['h-4', 'w-4'],
    md: ['h-5', 'w-5'],
    lg: ['h-5', 'w-5'],
    xl: ['h-5', 'w-5'],
  }

  const iconCn = cn('-ml-1 mr-2 text-gray-400 inline-flex', iconSizeCn[size])
  const iconOnlyCn = cn('text-gray-400', iconSizeCn[size])

  return (
    <span className={spanCn}>
      {items.map((item, index) => {
        const {
          icon,
          iconProps = {},
          iconOnly = false,
          isStat = false,
          component: Component,
          componentProps = {},
        } = item

        const { className, children, disabled, ...rest } = componentProps

        const isFirst = index === 0
        const isMiddle = index < items.length - 1
        const isLast = index === items.length - 1

        const buttonCn = cn(buttonBaseCn, buttonSizeCn[size], {
          [buttonActiveCn.join(' ')]: !disabled,
          'cursor-auto': disabled,
          'px-4': !iconOnly && !isStat,
          'px-3': isStat || iconOnly,
          //'px-2': iconOnly,
          'rounded-l-md': isFirst,
          '-ml-px': isMiddle,
          '-ml-px rounded-r-md': isLast,
          [className]: true,
        })

        return (
          <Component
            className={buttonCn}
            disabled={disabled}
            {...rest}
            key={index}
          >
            <span
              className={cn('flex items-center', { 'opacity-50': disabled })}
            >
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  className={iconOnly ? iconOnlyCn : iconCn}
                  size="1x"
                  {...iconProps}
                />
              )}
              {children}
            </span>
          </Component>
        )
      })}
    </span>
  )
}

export default ButtonGroup
