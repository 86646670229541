import React from 'react'

import usePrivateApi from '../../hooks/usePrivateApi'
import useObjState from '../../hooks/useObjState'
import Dialog from '../Dialog'
import InputGroup from '../InputGroup'
import { getCountryOptions } from '../../utils/address'
import Button from '../Button'

const PaymentMethodAddressModal = (props) => {
  const { admin, dealerId, isOpen, setIsOpen, method } = props

  const [address, setAddress] = useObjState({
    line1: method.address.line1 || '',
    line2: method.address.line2 || '',
    city: method.address.city || '',
    region: method.address.region || '',
    postcode: method.address.postcode || '',
    country: method.address.country || 'GB',
  })

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    if (admin) {
      sendRequest({
        url: `/admin/dealers/${dealerId}/payment-methods/${method.id}`,
        method: 'patch',
        data: { address },
      })
    } else {
      sendRequest({
        url: `/billing/payment-methods/${method.id}`,
        method: 'patch',
        data: { address },
      })
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not save billing address. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Saved billing address"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Payment method billing address has been updated.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Change billing address"
      buttonProps={{
        disabled: isLoading || !address.line1 || !address.postcode,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save address',
        color: 'green',
      }}
    >
      <div className="space-y-4 mt-2">
        <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
          <InputGroup
            required
            className="flex-1"
            label="Address line one"
            id="addressLine1"
            disabled={isLoading}
            onChange={(e) => setAddress({ line1: e.target.value })}
            value={address.line1}
          />
          <InputGroup
            className="flex-1"
            label="Address line two"
            id="addressLine2"
            disabled={isLoading}
            onChange={(e) => setAddress({ line2: e.target.value })}
            value={address.line2}
          />
        </div>
        <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
          <InputGroup
            className="flex-1"
            label="City/town"
            id="city"
            disabled={isLoading}
            onChange={(e) => setAddress({ city: e.target.value })}
            value={address.city}
          />
          <InputGroup
            className="flex-1"
            label="Region"
            id="region"
            disabled={isLoading}
            onChange={(e) => setAddress({ region: e.target.value })}
            value={address.region}
          />
        </div>
        <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
          <InputGroup
            required
            className="flex-1"
            label="Postcode"
            id="postcode"
            disabled={isLoading}
            onChange={(e) =>
              setAddress({
                postcode: e.target.value.toUpperCase(),
              })
            }
            value={address.postcode}
          />
          <InputGroup
            required
            className="flex-1"
            type="select"
            disabled={isLoading}
            label="Country"
            id="country"
            onChange={(e) => setAddress({ country: e.target.value })}
            renderOptions={getCountryOptions}
            value={address.country || 'GB'}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default PaymentMethodAddressModal
