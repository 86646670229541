import React from 'react'
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

const RadioCards = (props) => {
  const {
    selected,
    setSelected,
    groupLabel,
    items,
    disabled,
    Container = React.Fragment,
  } = props

  return (
    <HeadlessRadioGroup
      value={selected}
      onChange={setSelected}
      disabled={disabled}
    >
      {groupLabel && (
        <HeadlessRadioGroup.Label className="sr-only">
          {groupLabel}
        </HeadlessRadioGroup.Label>
      )}
      <Container>
        {items.map((item) => (
          <HeadlessRadioGroup.Option
            key={item.label}
            value={item.value}
            onClick={item.onClick || undefined}
            disabled={item.disabled}
            className={({ checked }) =>
              cn(
                'relative border flex-1 cursor-pointer rounded-md md:pl-4 md:pr-6 focus:outline-none',
                {
                  'flex items-center pl-4 py-3 space-x-2': !item.description,
                  'p-4': item.description,
                  'bg-green-50 border-tansparent z-10 ring-2 ring-green-500':
                    checked,
                  'bg-white border-gray-300 hover:bg-gray-50': !checked,
                }
              )
            }
          >
            {({ active, checked }) => (
              <>
                <FontAwesomeIcon
                  icon={item.icon}
                  size="lg"
                  className={cn({
                    'text-green-600': checked,
                    'text-gray-500': !checked,
                  })}
                />
                <div className={cn('flex-1', { 'mt-2': item.description })}>
                  <HeadlessRadioGroup.Label
                    as="p"
                    className="font-medium text-sm tracking-tight text-gray-900"
                  >
                    {item.label}
                  </HeadlessRadioGroup.Label>
                  {item.description && (
                    <HeadlessRadioGroup.Description
                      as="p"
                      className={cn('mt-1 text-xs', {
                        'text-gray-900': checked,
                        'text-gray-600': !checked,
                      })}
                    >
                      {item.description}
                    </HeadlessRadioGroup.Description>
                  )}
                  {checked && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="absolute top-2 right-2 text-green-600"
                    />
                  )}
                  {item.renderContent &&
                    item.renderContent({ active, checked })}
                </div>
              </>
            )}
          </HeadlessRadioGroup.Option>
        ))}
      </Container>
    </HeadlessRadioGroup>
  )
}

export default RadioCards
