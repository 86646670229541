import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({ links }) => {
  return (
    <nav>
      <ol className="flex items-center">
        {links.map((link) => {
          const linkCn = cn(
            'text-sm flex items-center whitespace-nowrap list-none after:px-2 after:text-gray-400',
            {
              "text-green-600 after:content-['/']": link.to,
              "text-gray-600 after:content-['']": !link.to,
            }
          )

          if (!link.to) {
            return (
              <li className={linkCn} key={link.label}>
                {link.label}
              </li>
            )
          }

          return (
            <li className={linkCn} key={link.label}>
              <Link to={link.to}>{link.label}</Link>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
