import React, { useMemo } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import cn from 'classnames'

const Dropdown = (props) => {
  const { buttonText, items, renderButton } = props

  const itemsJsx = useMemo(() => {
    const renderItem = (item) => {
      const {
        label,
        icon,
        renderItem: renderCustomItem,
        disabled,
        as: Component = 'button',
        ...rest
      } = item

      if (renderCustomItem) {
        return (
          <Menu.Item disabled={disabled} key={label || `${Math.random()}`}>
            {renderCustomItem}
          </Menu.Item>
        )
      }

      return (
        <Menu.Item disabled={disabled} key={label}>
          {({ active }) => {
            const className = cn(
              'group flex w-full items-center rounded-md px-3 py-2 text-sm',
              {
                'bg-green-600 text-white': active,
                'text-gray-900': !active,
              }
            )

            const iconClassName = cn('mr-3 w-6 h-auto', {
              'text-green-500': !active,
              'text-white': active,
            })

            return (
              <Component className={className} {...rest}>
                {icon && (
                  <FontAwesomeIcon icon={icon} className={iconClassName} />
                )}
                {label}
              </Component>
            )
          }}
        </Menu.Item>
      )
    }

    return items.map((item, index) => {
      if (Array.isArray(item)) {
        const itemsJsx = item.map(renderItem)
        return (
          <div className="px-1 py-1" key={index}>
            {itemsJsx}
          </div>
        )
      }

      return (
        <div className="px-1 py-1" key={index}>
          {renderItem(item)}
        </div>
      )
    })
  }, [items])

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {renderButton ? (
          renderButton({ MenuButton: Menu.Button })
        ) : (
          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {buttonText}
            <FontAwesomeIcon
              icon={faChevronDown}
              className="ml-2 -mr-1 h-5 w-5 text-green-200 hover:text-green-100"
              aria-hidden="true"
              size="md"
            />
          </Menu.Button>
        )}
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {itemsJsx}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
