import React from 'react'
import cn from 'classnames'
import { Disclosure } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

const Accordion = ({ title, content }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <Disclosure.Button
            className={cn(
              'bg-white border border-gray-200 px-4 py-4 text-left flex items-center justify-between w-full hover:bg-gray-50',
              {
                'rounded-md': !open,
                'rounded-tr-md rounded-tl-md': open,
              }
            )}
          >
            <p className="text-sm font-medium text-gray-900 pr-6">{title}</p>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={cn('text-green-500', {
                'transform rotate-180': open,
              })}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="bg-white border-x border-b border-gray-200 rounded-bl-md rounded-br-md px-4 py-3 text-sm text-gray-700 whitespace-pre-wrap">
            {content}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default Accordion
