import React, { useState } from 'react'

import FileUploadModal from '../FileUploadModal'
import Button from '../Button'
import FileListItem from './FileListItem'
import { UPLOAD_FILES } from '../../utils/sockets'

const FileList = (props) => {
  const {
    isAdmin,
    isAdminFileList,
    description,
    files,
    heading,
    noFilesText,
    onNewFiles,
    onNewFilesError,
    socket,
    isUpload,
    isSupport,
    isDisabled,
  } = props

  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const handleUploadComplete = (files) => {
    if (files.length > 0) {
      const fileIds = files.map((file) => file.id)

      return socket.emit(UPLOAD_FILES, fileIds, (err, allFiles) => {
        if (allFiles) {
          onNewFiles(allFiles)
          setUploadModalOpen(false)
        }

        if (err) {
          onNewFilesError()
        }
      })
    }

    return setUploadModalOpen(false)
  }

  const getBucket = () => {
    if (isSupport) {
      return 'SUPPORT'
    } else if (isUpload) {
      return 'UPLOADS'
    }
  }

  const shouldRenderActions = () => {
    if (onNewFiles && !isDisabled) {
      if (isAdmin && isAdminFileList) {
        return true
      } else if (!isAdmin && !isAdminFileList) {
        return true
      }

      return false
    }
  }

  const filteredFiles = files.filter((file) =>
    isAdminFileList
      ? file.createdBy.accountType !== 'USER'
      : file.createdBy.accountType === 'USER'
  )

  return (
    <div className="py-6 px-6">
      <div>
        {heading && (
          <h4 className="font-semibold tracking-tight text-lg mb-0.5">
            {heading}
          </h4>
        )}
        {description && <p className="text-gray-600 text-sm">{description}</p>}
      </div>
      {filteredFiles.length > 0 ? (
        <div className="mt-4">
          {filteredFiles.map((file) => (
            <FileListItem
              key={file.id}
              file={file}
              socket={socket}
              isAdmin={isAdmin}
              isAdminFile={isAdminFileList}
            />
          ))}
        </div>
      ) : (
        <div className="mt-4 mb-6 bg-gray-50 border border-gray-100 rounded-md px-4 py-3">
          <p className="text-gray-600 text-sm">{noFilesText}</p>
        </div>
      )}
      {shouldRenderActions() && (
        <div className="mt-5">
          <Button
            color={isUpload ? 'green' : 'white'}
            onClick={() => setUploadModalOpen(true)}
          >
            Upload files
          </Button>
          <FileUploadModal
            isOpen={uploadModalOpen}
            setIsOpen={() => setUploadModalOpen(false)}
            onUploadComplete={handleUploadComplete}
            bucket={getBucket()}
          />
        </div>
      )}
    </div>
  )
}

export default FileList
