import React, { useMemo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import BoxNav from '../../BoxNav'
import ViewUploadTitle from './Title'
import ViewUploadMessages from './Messages'
import ViewUploadFiles from './Files'
import ViewUploadData from './Data'
import ViewUploadAddons from './Addons'
import ViewUploadAdmin from './Admin'

const ViewUpload = (props) => {
  const {
    isAdmin,
    upload,
    setUpload,
    hasFetchedUpload,
    isLoading,
    match,
    location,
    socket,
    setConnectionErr,
  } = props

  const boxNavLinks = useMemo(() => {
    const links = [
      { to: `${match.url}/messages`, label: 'Messages' },
      { to: `${match.url}/files`, label: 'Uploaded files' },
      { to: `${match.url}/data`, label: 'Data' },
    ]

    if (upload.addons.length > 0) {
      links.push({ to: `${match.url}/addons`, label: 'Add-ons' })
    }

    if (isAdmin) {
      links.push({ to: `${match.url}/admin`, label: 'ADMIN' })
    }

    return links
  }, [match.url, upload.addons, isAdmin])

  return (
    <>
      <ViewUploadTitle isAdmin={isAdmin} upload={upload} location={location} />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          {!hasFetchedUpload || isLoading ? (
            <div className="flex items-center justify-center p-12 flex-1">
              <span className="spinner" />
            </div>
          ) : (
            <>
              <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
                <BoxNav current={location.pathname} links={boxNavLinks} />
              </div>
              <Switch>
                <Route
                  path={`${match.url}/messages`}
                  render={() => (
                    <ViewUploadMessages
                      upload={upload}
                      setUpload={setUpload}
                      socket={socket}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/files`}
                  render={() => (
                    <ViewUploadFiles
                      isAdmin={isAdmin}
                      upload={upload}
                      setUpload={setUpload}
                      socket={socket}
                      onError={() => setConnectionErr(true)}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/data`}
                  render={() => <ViewUploadData upload={upload} />}
                />
                {upload.addons.length > 0 && (
                  <Route
                    path={`${match.url}/addons`}
                    render={() => <ViewUploadAddons upload={upload} />}
                  />
                )}
                {isAdmin && (
                  <Route
                    path={`${match.url}/admin`}
                    render={() => (
                      <ViewUploadAdmin
                        upload={upload}
                        socket={socket}
                        setUpload={setUpload}
                      />
                    )}
                  />
                )}
                <Redirect
                  to={{
                    pathname: `${match.url}/messages`,
                    state: location.state,
                  }}
                />
              </Switch>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewUpload
