import React, { useMemo, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import StandardCreditPackageItem from './PackageItem'
import StandardCreditPackageNewModal from './ModalNewCreditPackage'
import StandardCreditPackageEditModal from './ModalEditCreditPackage'
import StandardCreditPackageDeleteModal from './ModalDeleteCreditPackage'

const StandardPackages = () => {
  const [creditPackages, setCreditPackages] = useState(null)
  const [newPackageOpen, setNewPackageOpen] = useState(false)
  const [editPackageId, setEditPackageId] = useState(null)
  const [deletePackageId, setDeletePackageId] = useState(null)

  const [{ res, success, error }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/admin/credits/packages' })
  }, [sendRequest])

  useEffect(() => {
    if (success) {
      const { creditPackages } = res.data
      setCreditPackages(creditPackages)
      resetSuccess()
    }
  }, [success, resetSuccess, res])

  const baseCost = useMemo(() => {
    if (creditPackages) {
      const basePackage = creditPackages.find((x) => x.credits === 1)
      return basePackage.cost
    }
  }, [creditPackages])

  const handleSuccess = (creditPackages) => {
    setNewPackageOpen(false)
    setEditPackageId(null)
    setDeletePackageId(null)
    setCreditPackages(creditPackages)
  }

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve packages
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (creditPackages === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <>
      <div className="px-6 py-6">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          What are standard packages?
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            These are the packages that will be automatically allocated to a
            dealer’s account during signup. New and edited standard packages
            will also be automatically applied to existing dealers that have no
            custom packages.
          </p>
        </div>
        <div className="my-5 space-y-3">
          {creditPackages
            .sort((a, b) => (a.cost > b.cost ? 1 : -1))
            .map((creditPackage) => (
              <StandardCreditPackageItem
                creditPackage={creditPackage}
                baseCost={baseCost}
                key={creditPackage.id}
                setEditPackageId={setEditPackageId}
                setDeletePackageId={setDeletePackageId}
              />
            ))}
        </div>
        <Button color="green" onClick={() => setNewPackageOpen(true)}>
          Add new package
        </Button>
      </div>
      <StandardCreditPackageNewModal
        isOpen={newPackageOpen}
        setIsOpen={setNewPackageOpen}
        onSuccess={handleSuccess}
      />
      <StandardCreditPackageEditModal
        isOpen={!!editPackageId}
        setIsOpen={setEditPackageId}
        onSuccess={handleSuccess}
        creditPackage={
          editPackageId && creditPackages.find((x) => x.id === editPackageId)
        }
      />
      <StandardCreditPackageDeleteModal
        isOpen={!!deletePackageId}
        setIsOpen={setDeletePackageId}
        onSuccess={handleSuccess}
        creditPackage={
          deletePackageId &&
          creditPackages.find((x) => x.id === deletePackageId)
        }
      />
    </>
  )
}

export default StandardPackages
