import React, { useState } from 'react'

import { Messages } from '../../Message'
import { SEND_MESSAGE } from '../../../utils/sockets'

const ViewUploadMessages = props => {
  const { upload, setUpload, socket } = props

  const [currentMessage, setCurrentMessage] = useState('')
  const [isMessageLoading, setIsMessageLoading] = useState(false)

  const handleOnMessageSend = () => {
    setIsMessageLoading(true)

    socket.emit(SEND_MESSAGE, currentMessage, (err, message) => {
      if (message) {
        setUpload({ messages: [...upload.messages, message] })
        setCurrentMessage('')
        setIsMessageLoading(false)
      }
    })
  }

  return (
    <Messages
      currentMessage={currentMessage}
      setCurrentMessage={setCurrentMessage}
      messages={upload.messages}
      onMessageSend={handleOnMessageSend}
      isMessageLoading={isMessageLoading}
    />
  )
}

export default ViewUploadMessages
