import React, { useState, useEffect, useContext } from 'react'
import {
  Router as ReactRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import useAuthRefresh from './hooks/useAuthRefresh'
import AuthContext from './context/AuthContext'
import Auth from './auth'
import Admin from './admin'
import Writer from './writer'
import FrontEnd from './front-end'
import history from './history'
import { isAdmin, isUser, isWriter } from './utils/auth'

const Router = () => {
  const [showApp, setShowApp] = useState(false)
  const [hasTried, isLoading] = useAuthRefresh()
  const [auth] = useContext(AuthContext)

  useEffect(() => {
    if (hasTried && !isLoading) {
      setShowApp(true)
    }
  }, [hasTried, isLoading])

  if (!showApp) {
    return (
      <div
        className="flex items-center justify-center bg-gray-light"
        style={{ height: '100vh', width: '100vw' }}
      >
        <span className="spinner" />
      </div>
    )
  }

  return (
    <ReactRouter history={history}>
      <Switch>
        <Route
          component={Auth}
          path={[
            '/login',
            '/reset-password',
            '/new-password',
            '/signup',
            '/signup-pending',
            '/subdealer-accept',
          ]}
        />
        {isAdmin(auth.membership) && (
          <Switch>
            <Route component={Admin} />
          </Switch>
        )}
        {isWriter(auth.membership) && (
          <Switch>
            <Route component={Writer} />
          </Switch>
        )}
        {isUser(auth.membership) && (
          <Switch>
            <Route component={FrontEnd} />
          </Switch>
        )}
        <Redirect to="/login" />
      </Switch>
    </ReactRouter>
  )
}

export default Router
