import React, { useMemo } from 'react'

import Title from '../../../components/Title'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Label from '../../../components/Label'
import { getStatus, getStatusLabelColor } from '../../../utils/dealers'

const ViewDealerTitle = ({ dealer, location }) => {
  const breadcrumbLinks = useMemo(() => {
    const dealerList = { label: 'Dealer list', to: '/dealers' }

    if (location?.state?.listState) {
      const { pathname, page, filters } = location.state.listState
      dealerList.to = { pathname, state: { page, filters } }
    }

    return [
      dealerList,
      {
        label: (
          <>
            DK number {dealer.dkNumber}{' '}
            <Label color={getStatusLabelColor(dealer.status)} className="ml-2">
              {getStatus(dealer.status)}
            </Label>
          </>
        ),
      },
    ]
  }, [dealer, location])

  return (
    <Title
      title={dealer.name ? dealer.name : '-'}
      renderBreadcrumbs={() => <Breadcrumbs links={breadcrumbLinks} />}
    />
  )
}

export default ViewDealerTitle
