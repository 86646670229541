import React, { useState, useEffect } from 'react'

import useObjState from '../../../hooks/useObjState'
import usePrivateApi from '../../../hooks/usePrivateApi'
import InputGroup from '../../../components/InputGroup'
import Label from '../../../components/Label'
import Button from '../../../components/Button'

const BillingVat = (props) => {
  const { showContent, setFetchedVat, setFetchError } = props

  const [savedNumber, setSavedNumber] = useState('')
  const [saveErr, setSaveErr] = useState(null)
  const [vat, setVat] = useObjState({
    number: '',
    verified: null,
    verifiedAddress: null,
  })

  const [
    { res, success, error, isLoading },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/settings/vat' })
  }, [sendRequest])

  useEffect(() => {
    if (success && !showContent) {
      setFetchedVat(true)
      resetSuccess()
      setVat(res.data.vat)
      setSavedNumber(res.data.vat.number)
    }
  }, [res, setVat, success, resetSuccess, showContent, setFetchedVat])

  useEffect(() => {
    if (success && showContent) {
      resetSuccess()
      setVat(res.data.vat)
      setSavedNumber(res.data.vat.number)
    }
  }, [success, showContent, res, resetSuccess, setVat])

  useEffect(() => {
    if (error) {
      if (error && showContent) {
        if (error.response) {
          setSaveErr('Error saving VAT number. Please try again later.')
        } else if (error.request) {
          setSaveErr('Please check your internet connection')
        } else {
          setSaveErr('Error saving VAT number. Please try again later.')
        }
      } else {
        setFetchError(true)
      }
      resetError()
    }
  }, [error, resetError, showContent, setFetchError])

  const handleSave = () => {
    setSaveErr(null)
    sendRequest({
      url: '/settings/vat',
      method: 'patch',
      data: { vatNumber: vat.number },
    })
  }

  const isValid = vat.number !== savedNumber

  return (
    <div
      className={
        !showContent
          ? 'hidden'
          : 'border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start'
      }
    >
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          VAT number
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            If you are based inside the EU and have a valid EU VAT number enter
            it here. Once the number has been verified we will not charge you
            VAT.
          </p>
          <p>Please note that this does not apply to UK based businesses.</p>
        </div>
      </div>
      <div className="flex-1">
        <InputGroup
          label="VAT number"
          id="vatNumber"
          className="max-w-xs"
          disabled={isLoading}
          help="Include country code, for example IE1234567FA."
          onChange={(e) => setVat({ number: e.target.value.toUpperCase() })}
          value={vat.number}
        />
        <label className="block text-sm font-medium mt-4">
          Status
          <Label className="ml-3" color={vat.verified ? 'green' : 'gray'}>
            {vat.verified ? 'Verified' : 'Not verified'}
          </Label>
        </label>
        {vat.verified && (
          <>
            <label className="block text-sm font-medium mt-3">
              Verfied address
            </label>
            <p className="text-sm text-gray-600">{vat.verifiedAddress}</p>
          </>
        )}
        <Button
          color="green"
          className="mt-6"
          disabled={!isValid || isLoading}
          onClick={handleSave}
        >
          {isLoading ? 'Verifing...' : 'Save & verify'}
        </Button>
        {saveErr && <p className="text-red-600 mt-3 text-sm">{saveErr}</p>}
      </div>
    </div>
  )
}

export default BillingVat
