import React from 'react'
import { isFuture } from 'date-fns'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const EnableAutoRenewModal = ({ isOpen, tool, hasPaymentMethod }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/tools/${tool.id}/subscription`,
      method: 'post',
    })
  }

  if (!isFuture(new Date(tool.nextExpiry))) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Cannot enable auto-renew"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          We could not enable auto-renew because your expiry date has already
          past.
        </p>
        <p className="text-gray-600 text-sm mt-2">
          Please create a support ticket to renew your subscription. Once your
          subscription is up-to-date you can enable auto-renew if you wish.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (!hasPaymentMethod) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Cannot enable auto-renew"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          We could not enable auto-renew because you do not have a payment
          method in your account.
        </p>
        <p className="text-gray-600 text-sm mt-2">
          Please add a payment method by going to the "billing" section of your
          account settings.
        </p>
        <p className="text-gray-600 text-sm mt-2">
          Once you have added a payment method please try again.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not enable auto-renew. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Auto-renew is enabled"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          You have successfully enabled auto-renew for your {tool.name}. We'll
          send you an email reminder 7 days before payment is collected.
          Remember, you can cancel auto-renew at any time.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => window.location.reload()}
      title="Enable auto-renew"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Enabling...' : 'Enable',
        color: 'green',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        When enabling auto-renew the following will happen:
      </p>
      <h5 className="text-sm font-medium mt-4">10 days before expiry</h5>
      <p className="text-gray-600 text-sm mt-1">
        We'll email you a reminder that we will take payment in the next 7 days.
      </p>
      <h5 className="text-sm font-medium mt-4">3 days before expiry</h5>
      <p className="text-gray-600 text-sm mt-1">
        We attempt to take payment for your tool subscription using your primary
        payment method.
      </p>
      <h5 className="text-sm font-medium mt-4">1 day before expiry</h5>
      <p className="text-gray-600 text-sm mt-1">
        We'll renew your tool subscription for another year.
      </p>
      <p className="text-sm font-medium mt-4">
        You can turn off auto-renew at any point.
      </p>
    </Dialog>
  )
}

export default EnableAutoRenewModal
