import React, { useState } from 'react'

import Button from '../../../../../components/Button'
import { getStatusOptions } from '../../../../../utils/dealers'
import { CHANGE_DEALER_STATUS } from '../../../../../utils/sockets'
import InputGroup from '../../../../../components/InputGroup'

const ViewDealerAccountStatus = ({ dealer, setDealer, socket }) => {
  const [status, setStatus] = useState(dealer.status)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isSaved, setIsSaved] = useState(false)

  const handleChange = (e) => {
    if (isSaved) {
      setIsSaved(false)
    }

    setStatus(e.target.value || null)
  }

  const handleSubmit = () => {
    setIsLoading(true)
    setError(null)

    socket.emit(CHANGE_DEALER_STATUS, status, (err, success) => {
      setIsLoading(false)

      if (success) {
        setDealer({ status })
        setIsSaved(true)
      }

      if (err) {
        setError(true)
      }
    })
  }

  return (
    <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Account status
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            The status of the dealer account on the system. This applies to all
            users linked to the dealer. See the table below on what each status
            means.{' '}
          </p>
        </div>
      </div>
      <div className="flex-1">
        <InputGroup
          required
          className="max-w-xs"
          type="select"
          label="Status"
          id="accountStatus"
          disabled={isLoading}
          onChange={handleChange}
          value={status || ''}
          renderOptions={getStatusOptions}
        />
        <Button
          color="green"
          className="mt-4"
          disabled={isLoading || status === dealer.status}
          onClick={handleSubmit}
        >
          {isSaved && 'Status saved'}
          {isLoading && 'Saving...'}
          {!isSaved && !isLoading && 'Save status'}
        </Button>
        <div>
          {error && (
            <p className="text-red-600 my-5 text-sm">An error occurred.</p>
          )}
          <p className="mt-5 font-medium text-red-600 uppercase text-sm">
            WARNING
          </p>
          <p className="text-red-600 text-sm">
            Changing the status of a dealer will impact their ability to use the
            system. Only do this if you have authority to do so.
          </p>
          <div className="mt-4 bg-gray-100 rounded-md px-4 pt-4 pb-2">
            <h4 className="text-sm font-medium mb-4">
              What does each status do?
            </h4>
            <h5 className="font-medium text-sm">Active</h5>
            <p className="text-sm text-gray-600 mb-4">
              Dealer can login and has full access to the system.
            </p>
            <h5 className="font-medium text-sm">Pending</h5>
            <p className="text-sm text-gray-600 mb-4">
              Dealer has created an account and is awaiting approval.
            </p>
            <h5 className="font-medium text-sm">On hold</h5>
            <p className="text-sm text-gray-600 mb-4">
              Dealer can login but cannot purchase credits, upload files or
              create support tickets.
            </p>
            <h5 className="font-medium text-sm">Archived</h5>
            <p className="text-sm text-gray-600 mb-4">
              Dealer cannot login to system.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewDealerAccountStatus
